import React, { useState } from "react";
import Context from "./Context";


export default function ContextWrapper(props) {
  const [title, setTitle] = useState([]);

  return (
    <Context.Provider
      value={{ title, setTitle }}
    >
      {props.children}
    </Context.Provider>
  );
}
