import React, { useEffect } from 'react';
import {
    useRecoilState,
    useRecoilValue,
} from 'recoil';
import { ProfileState, profileLength } from "../Atoms/ProfileAtom";
import ProfileTag from "./ProfileTag"
import { cloneDeep } from 'lodash'
import { update } from "../API/Agent";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';




const ProfileTags = () => {
    const [profile, setProfile] = useRecoilState(ProfileState);
    const [del, setDelOpen] = React.useState(false);
    const [tag, setTag] = React.useState({});
    const [tags, setTags] = React.useState(profile.member_numbers === undefined ? [] : profile.member_numbers);
    const [delTag, setdelTag] = React.useState(false);
    const count = useRecoilValue(profileLength);

    const handleClose = () => {
        setDelOpen(false);
    };
    const handleTagDeleteClose = () => {
        setdelTag(true);
    }
    const handleTagDeleteCloseX = () => {
        setdelTag(false);
    }
    const handleDeleteTagAndClose = () => {
        setdelTag(false);

        console.log(tag);
        console.log(profile);
        // 
        let profileClone = cloneDeep(profile)
        setTags(profileClone.member_numbers);
        console.log(tags);
        //const itemIndex = tags.findIndex((e) => e.member_number === tag.member_number)

        let filterTags = tags.filter(item => item.member_number !== tag.member_number)
        setTags(filterTags);
        profileClone.member_numbers = filterTags;

        //console.log(profileClone);
        try {

            // console.log(profileClone);
            update(profileClone, profile._id).then((response) => {

                if (response !== '—') {


                    setProfile(response);
                    setDelOpen(true);
                } else {
                    //do something here placeholder
                }
            });
        } catch (error) {

            console.log({ ...error })

        }
    }

    const deleteItemCallback = (tag) => {
        setTag(tag);
        handleTagDeleteClose();
    }

    useEffect(() => {

        setTags(profile.member_numbers === undefined ? [] : profile.member_numbers);
    }, [count]);

    return (
        <>
            {tags.map((item, index) => {
                return (


                    <>
                        <ProfileTag key={index} tagItem={item} deleteItemCallback={deleteItemCallback} />
                        {index === 11 &&
                            <div class="line-break"></div>
                        }
                    </>

                )
            })}

            <Dialog
                open={del}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ textAlign: "center" }}
            >
                <DialogTitle id="alert-dialog-title">
                    {"ICE-TAGS USER DELETED"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You have successfully deleted {tag.member_number} from your profile.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Ok</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={delTag} onClose={handleTagDeleteCloseX} sx={{ textAlign: "center" }}>
                <DialogTitle>DELETE RESQTAP</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please confirm deletion of this RESQTAP, once deleted a reconnection fee will be applicable.
                        <br /> <br />
                        ARE YOU SURE?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleTagDeleteCloseX}>Cancel</Button>
                    <Button onClick={handleDeleteTagAndClose}>Delete Tag</Button>
                    {/* sendUpdate(rowData); */}
                </DialogActions>
            </Dialog>
        </>

    )
};
export default ProfileTags 