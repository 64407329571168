import React from 'react';
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { register } from "../API/Agent";
import logo from '../logo.png'
import Copyright from './Copyright';


const theme = createTheme();


const SignUp = () => {
    const [open, setOpen] = React.useState(false);
    const [checked, setChecked] = React.useState(false);
    let navigate = useNavigate();
    const handleClickOpen = () => {
        setOpen(true);

    };

    const handleClose = () => {
        setOpen(false);
    };

   const handleChange =(e) => {
        let isChecked = e.target.checked;
        setChecked(isChecked);
      }

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // eslint-disable-next-line no-console
        let userRegister =
        {
            email: data.get('email'),
            password: data.get('password'),
            firstname: data.get('firstName'),
            lastname: data.get('lastName'),
            id_number: data.get('id_number'),
            allowmarketing: checked,
        };
        
        try {
            console.log(userRegister);
            register(userRegister).then((response) => {

                if (response !== "undefined") {
                    handleClickOpen(); //fix this
                    navigate("/login");
                } else {
                    //do something here placeholder
                }
            });


        } catch (error) {
            console.log({ ...error })

        }

    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <img
                        sx={{ m: 1, bgcolor: 'secondary.main' }}
                        alt="ICE-TAGS"
                        src={logo}
                    />
                    <Typography component="h1" variant="h5">
                        Sign up
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="given-name"
                                    name="firstName"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label="First Name"
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="lastName"
                                    label="Last Name"
                                    name="lastName"
                                    autoComplete="family-name"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="id_number"
                                    label="ID Number"
                                    name="id_number"
                                    autoComplete="id_number"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="new-password"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox  value="allowExtraEmails" color="primary" onChange={handleChange} />}
                                    label="I want to receive inspiration, marketing promotions and updates via email."
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign Up
                        </Button>
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {"ICE-TAGS User Created"}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Welcome onboard RESQTAP. Thank you for joining. An email has been sent to you detailing how to activate your login. Thank you for joining us. And remember - Be Prepared, Save a Life!™️.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                {/* <Button onClick={handleClose}>Disagree</Button> */}
                                <Button onClick={handleClose} autoFocus>
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Link href="/login" variant="body2">
                                    Already have an account? Sign in
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 5 }} />
            </Container>
        </ThemeProvider>
    );
};

export default SignUp