// src/atoms/name.js

import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';

import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const TokenState = atom({
  key: "TokenState",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export const TokenStateChange = selector({
  key: "TokenStateChange",
  get:({get})=>{
    return get(TokenState);
  },
  set:({set},newValue)=>  set(TokenState, newValue)
})