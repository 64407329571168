import React, { useState, useEffect,useContext } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
//constants

import Loading from './Loading';
import logo from '../logo.png'
import pets from '../pets.png'
//MUI
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import Fab from '@mui/material/Fab';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from "@mui/material/Paper";

import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import yellow from "@mui/material/colors/yellow";

import Stack from '@mui/material/Stack';
//icons
import { styled } from '@mui/material/styles';
import Copyright from './Copyright';
import EmergencyIcon from "@material-ui/icons/ContactPhone";
import { geo, getUser,getProfileByEmail} from "../API/Agent";
import { makeStyles } from "@material-ui/core/styles";
import GlobalContext from "../Context/Context";
const useStyles = makeStyles({
    input1: {
        fontSize: "1vw"
    },
    input2: {
        fontSize: "14px",
        textTransform: 'capitalize'
    },
    input3: {
        fontSize: "14px",
        textTransform: 'lowercase'
    },
    input4: {
        fontSize: "14px",
        color: "black"
    }
});
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


const theme = createTheme({
    palette: {
        primary: {
            light: '#757ce8',
            main: '#1976D2',
            dark: '#FFD700',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#fff',
        },
    },
});



const Tag = () => {
    const { title, setTitle} = useContext(GlobalContext);
    let navigate = useNavigate();
    let { id } = useParams();
    const [userItem, setUser] = useState([]);
    const [profile, setProfile] = useState({});
    const [cellNumber, setCellNumber] = useState('');
    const [isPet, setIsPet] = useState(false);
    const [isPT, setIsPT] = useState(false);
    const classes = useStyles();
    useEffect(() => {
        getUser(id).then((resp) => {
            if (resp === undefined) {

                navigate("/error/ /true");
            } else {
                setUser(resp);
                setIsPet(resp.member_number.substring(0, 3) === "RTP");
                setIsPT(resp.member_number.substring(0, 2) === "PT");
                resp.member_number.substring(0, 3) === "RTP"?setTitle("RESQTAP PETS") : setTitle("RESQTAP");
               //call the users profile and get there cell number
                getProfileByEmail(resp.email.ToLowerCase()).then((response) => {
                    if (response === undefined) {
                
                        navigate("/error/ /true");
                    } else {
                        setProfile(response);
                        setCellNumber(response.cellNumber);
                    }
                });
            }

        });
//getProfileByEmail

    }, [id])

    const getMonthDifference = (tag) => {
        const startDate = new Date(tag.creditDate)
        const endDate = new Date();
        const credit = parseInt(tag.creditCount);
        var diff = endDate.getMonth() -
            startDate.getMonth() +
            12 * (endDate.getFullYear() - startDate.getFullYear());

        return credit - diff;
    }

    const GetRemainingCredits = () => {

        return getMonthDifference(userItem);
    }
    return (
        (userItem ?? []).length === 0 ?
            <Loading />
            :
            <React.Fragment>

                <ThemeProvider theme={theme}>

                    <Paper elevation={12} style={{
                        margin: "auto",
                        width: '80%',

                        border: `5px solid ${yellow[100]}`,
                        backgroundColor: yellow[50],
                        borderRadius: 25,
                    }} >
                        <Box p={1}>
                            <Container component="main" maxWidth="xs">
                                <CssBaseline />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Stack spacing={6} direction="row" sx={{ mb: 3 }}>
                                        <Item>
                                            <Tooltip title={(userItem.ice1_no === "Not Applicable" || userItem.ice1_no.trim().length === 0) ? "" : `Call  ${userItem.ice1_name} & send your Geolocation`} arrow>
                                                <Badge badgeContent={"ICE1"} color="secondary">
                                                    <Fab disabled={userItem.ice1_no === "Not Applicable" || userItem.ice1_no.trim().length === 0} color="primary" aria-label="ICE-1" onClick={() => {
                                                        geo(userItem.ice1_no.trim(), userItem.ice1_name);
                                                        setTimeout(function () {
                                                            window.location.href = `tel:${userItem.ice1_no}`;
                                                        }, (3 * 1000));

                                                    }}>
                                                        <EmergencyIcon />

                                                    </Fab>
                                                </Badge>
                                            </Tooltip>
                                        </Item>
                                        <Item>
                                            <Tooltip title={(userItem.ice2_no === "Not Applicable" || userItem.ice2_no.trim().length === 0) ? "" : `Call  ${userItem.ice2_name} & send your Geolocation`} arrow>
                                                <Badge badgeContent={"ICE2"} color="secondary">
                                                    <Fab disabled={userItem.ice2_no === "Not Applicable" || userItem.ice2_no.trim().length === 0} color="primary" aria-label="ICE-2" onClick={() => {
                                                        geo(userItem.ice2_no.trim(), userItem.ice2_name);
                                                        setTimeout(function () {
                                                            window.location.href = `tel:${userItem.ice2_no}`;
                                                        }, (3 * 1000));
                                                    }}>
                                                        <EmergencyIcon />
                                                    </Fab>
                                                </Badge>
                                            </Tooltip>
                                        </Item>
                                        <Item>
                                            <Tooltip title={(userItem.ice3_no === "Not Applicable" || userItem.ice3_no.trim().length === 0) ? "" : `Call  ${userItem.ice3_name} & send your Geolocation`} arrow>
                                                <Badge badgeContent={isPet ? "VET" : "ICE3"} color="secondary">
                                                    <Fab disabled={userItem.ice3_no === "Not Applicable" || userItem.ice3_no.trim().length === 0} color="primary" aria-label="ICE-3" onClick={() => {
                                                        geo(userItem.ice3_no.trim(), userItem.ice3_name);
                                                        setTimeout(function () {
                                                            window.location.href = `tel:${userItem.ice3_no}`;
                                                        }, (3 * 1000));
                                                    }}>
                                                        <EmergencyIcon />
                                                    </Fab>
                                                </Badge>
                                            </Tooltip>
                                        </Item>
                                    </Stack>
                                    {isPet ? <img width={200} height={200} alt={userItem.names} src={pets}  />
                                        : <img width={200} height={200} alt={userItem.names} src={logo} />
                                    }

                                    <Typography component="h1" variant="h5">
                                        Be Prepared, Save a Life!™️
                                    </Typography>
                                    <Avatar
                                        variant={"rounded"}
                                        alt={userItem.names}
                                        src={userItem.photo}
                                        style={{
                                            width: 200,
                                            height: 200,

                                        }} />
                                    <Box component="form" noValidate sx={{ mt: 3 }}>
                                        <Grid container spacing={2}>
                                            {userItem.names === 'Not Applicable' ? null :
                                                <Grid item xs={12} >
                                                    <TextField
                                                        multiline
                                                        fullWidth
                                                        variant="outlined" size="small"
                                                        label={isPet ? "PET NAME" : "NAME & SURNAME"}
                                                        defaultValue={userItem.names}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                            }
                                            {userItem.id_number === 'Not Applicable' ? null :
                                                <Grid item xs={12} >
                                                    <TextField

                                                        fullWidth
                                                        variant="outlined" size="small"
                                                        label={isPet ? "BIRTHDAY" : "ID OR PASSPORT NUMBER"}
                                                        defaultValue={userItem.id_number}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                            }
                                            {userItem.pet_type === 'Not Applicable' || !isPet ? null :
                                                <Grid item xs={12} >
                                                    <TextField

                                                        fullWidth
                                                        variant="outlined" size="small"
                                                        label={"PET TYPE"}
                                                        defaultValue={userItem.pet_type}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                            }
                                            {userItem.breed === 'Not Applicable' || !isPet ? null :
                                                <Grid item xs={12} >
                                                    <TextField

                                                        fullWidth
                                                        variant="outlined" size="small"
                                                        label={"BREED"}
                                                        defaultValue={userItem.breed}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                            }
                                            {userItem.colour === 'Not Applicable' || !isPet ? null :
                                                <Grid item xs={12} >
                                                    <TextField

                                                        fullWidth
                                                        variant="outlined" size="small"
                                                        label={"COLOUR"}
                                                        defaultValue={userItem.colour}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                            }
                                            {userItem.gender === 'Not Applicable' || !isPet ? null :
                                                <Grid item xs={12} >
                                                    <TextField

                                                        fullWidth
                                                        variant="outlined" size="small"
                                                        label={"GENDER"}
                                                        defaultValue={userItem.gender}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                            }
                                            {userItem.sterilized === 'Not Applicable' || !isPet ? null :
                                                <Grid item xs={12} >
                                                    <TextField

                                                        fullWidth
                                                        variant="outlined" size="small"
                                                        label={"STERILIZED"}
                                                        defaultValue={userItem.sterilized}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                            }
                                            {userItem.chipped === 'Not Applicable' || !isPet ? null :
                                                <Grid item xs={12} >
                                                    <TextField

                                                        fullWidth
                                                        variant="outlined" size="small"
                                                        label={"CHIPPED"}
                                                        defaultValue={userItem.chipped}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                            }
                                            {userItem.vaccination_code === 'Not Applicable'  || isPet || isPT ? null :
                                                <Grid item xs={12} >
                                                    <TextField
                                                        multiline
                                                        variant="outlined" size="small"
                                                        fullWidth
                                                        label="COVID-19 VACCINATION CODE"
                                                        defaultValue={userItem.vaccination_code}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                            }
                                            {userItem.travelvaccination === 'Not Applicable' || isPet || isPT ? null :
                                                <Grid item xs={12} >
                                                    <TextField
                                                        multiline
                                                        variant="outlined" size="small"
                                                        fullWidth
                                                        label="TRAVEL VACCINATIONS"
                                                        defaultValue={userItem.travelvaccination}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>}
                                            {userItem.allergies === 'Not Applicable' || isPT  ? null   :
                                                <Grid item xs={12} >
                                                    <TextField
                                                        multiline
                                                        variant="outlined" size="small"
                                                        fullWidth
                                                        label="ALLERGIES (PER LINE)"
                                                        defaultValue={userItem.allergies}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                            }
                                            {userItem.medical_contions === 'Not Applicable' || isPT ? null :
                                                <Grid item xs={12} >
                                                    <TextField
                                                        variant="outlined" size="small"
                                                        fullWidth
                                                        label="MEDICAL CONDITIONS (PER LINE)"
                                                        multiline
                                                        defaultValue={userItem.medical_contions}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />

                                                </Grid>
                                            }
                                            {userItem.medication === 'Not Applicable' || isPT  ? null :
                                                <Grid item xs={12} >
                                                    <TextField
                                                        variant="outlined" size="small"
                                                        fullWidth
                                                        label="CHRONIC MEDICATION (PER LINE)"
                                                        multiline
                                                        defaultValue={userItem.medication}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                            }
                                            {userItem.medical_aid === 'Not Applicable' || isPT ? null :
                                                <Grid item xs={12} >
                                                    <TextField
                                                        multiline
                                                        variant="outlined" size="small"
                                                        fullWidth
                                                        label={isPet?'PET INSURANCE NAME': 'MEDICAL AID NAME'}
                                                        defaultValue={userItem.medical_aid}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                            }
                                            {userItem.medical_aid_no === 'Not Applicable' || isPT ? null :
                                                <Grid item xs={12} >
                                                    <TextField
                                                        multiline
                                                        variant="outlined" size="small"
                                                        fullWidth
                                                        label={isPet?'PET INSURANCE NUMBER': 'MEDICAL AID NUMBER'}
                                                        defaultValue={userItem.medical_aid_no}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                            }
                                            {userItem.blood_group === 'Not Applicable' || isPT || isPet ? null :
                                                <Grid item xs={12} >
                                                    <TextField
                                                        multiline
                                                        variant="outlined" size="small"
                                                        fullWidth
                                                        label="BLOOD GROUP"
                                                        defaultValue={userItem.blood_group}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                            }
                                            {userItem.notes === 'Not Applicable' || isPT ? null :
                                                <Grid item xs={12} >
                                                    <TextField
                                                        variant="outlined" size="small"
                                                        fullWidth
                                                        label="SPECIAL REQUESTS / NOTES"
                                                        defaultValue={userItem.notes}
                                                        multiline
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                            }

                                            {userItem.ice1_name === 'Not Applicable' ? null :
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        multiline
                                                        variant="outlined" size="small"
                                                        fullWidth
                                                        label="ICE CONTACT 1"
                                                        defaultValue={userItem.ice1_name}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                            }
                                            {userItem.ice1_no === 'Not Applicable' ? null :
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" sx={{ p: 1, border: '2px solid red' }} >
                                                        {(userItem.ice1_no === "Not Applicable" || userItem.ice1_no.trim().length === 0) ? "Not Applicable" : (
                                                            <Link href={`tel:${userItem.ice1_no}`} variant="body2">
                                                                {"Call ICE "} {userItem.ice1_no}
                                                            </Link>)}
                                                    </Box>
                                                </Grid>
                                            }

                                            {userItem.ice2_name === 'Not Applicable' ? null :
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        multiline
                                                        variant="outlined" size="small"
                                                        fullWidth
                                                        label="ICE CONTACT 2"
                                                        defaultValue={userItem.ice2_name}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                            }
                                            {userItem.ice2_no === 'Not Applicable' ? null :
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" sx={{ p: 1, border: '2px solid red' }} >
                                                        {(userItem.ice2_no === "Not Applicable" || userItem.ice2_no.trim().length === 0) ? "Not Applicable" : (
                                                            <Link href={`tel:${userItem.ice2_no}`} variant="body2">
                                                                {"Call ICE "} {userItem.ice2_no}
                                                            </Link>)}
                                                    </Box>
                                                </Grid>
                                            }
                                            {userItem.ice3_name === 'Not Applicable' ? null :
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        multiline
                                                        variant="outlined" size="small"
                                                        fullWidth
                                                        label={isPet ? "VET" : "ICE CONTACT 3"}
                                                        defaultValue={userItem.ice3_name}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                            }
                                            {userItem.ice3_no === 'Not Applicable' ? null :
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" sx={{ p: 1, border: '2px solid red' }} >
                                                        {(userItem.ice3_no === "Not Applicable" || userItem.ice3_no.trim().length === 0) ? "Not Applicable" : (
                                                            <Link disabled href={`tel:${userItem.ice3_no}`} variant="body2">
                                                                {isPet ? "Call VET " : "Call ICE "}{userItem.ice3_no}
                                                            </Link>)}
                                                    </Box>
                                                </Grid>
                                            }
                                            {userItem.country === 'Not Applicable' || isPT ? null :
                                                <Grid item xs={12} >
                                                    <TextField
                                                        multiline
                                                        variant="outlined" size="small"
                                                        fullWidth
                                                        label="COUNTRY OF RESIDENCE"
                                                        defaultValue={userItem.country}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                            }
                                            {userItem.travelling_international === 'Not Applicable' || isPT ? null :
                                                <Grid item xs={12} >
                                                    <TextField
                                                        multiline
                                                        variant="outlined" size="small"
                                                        fullWidth
                                                        label="CURRENTLY TRAVELLING INTERNATIONALLY?"
                                                        defaultValue={userItem.travelling_international}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                            }
                                            {userItem.travelling_international === "NO - I am Local" || isPT ? null :
                                                <Grid item xs={12} >
                                                    <TextField
                                                        multiline
                                                        variant="outlined" size="small"
                                                        fullWidth
                                                        label="PURPOSE"
                                                        defaultValue={userItem.purpose}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>}


                                            {userItem.isvehicle ?
                                                <Grid item xs={12} >
                                                    <TextField
                                                        multiline
                                                        variant="outlined" size="small"
                                                        fullWidth
                                                        label="TRANSPORT INFORMATION: MOTOR VEHICLE"
                                                        defaultValue={userItem.vehicle}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                                : null}
                                            {userItem.istruck ?
                                                <Grid item xs={12} >
                                                    <TextField
                                                        multiline
                                                        variant="outlined" size="small"
                                                        fullWidth
                                                        label="TRANSPORT INFORMATION: TRUCK"
                                                        defaultValue={userItem.truck}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                                : null}
                                            {userItem.ismotorbike ?
                                                <Grid item xs={12} >
                                                    <TextField
                                                        multiline
                                                        variant="outlined" size="small"
                                                        fullWidth
                                                        label="TRANSPORT INFORMATION: MOTORCYCLE"
                                                        defaultValue={userItem.motorbike}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                                : null}
                                            {userItem.isbike ?
                                                <Grid item xs={12} >
                                                    <TextField
                                                        multiline
                                                        variant="outlined" size="small"
                                                        fullWidth
                                                        label="TRANSPORT INFORMATION: BICYCLE"
                                                        defaultValue={userItem.bike}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                                : null}
                                            {userItem.isother ?
                                                <Grid item xs={12} >
                                                    <TextField
                                                        multiline
                                                        variant="outlined" size="small"
                                                        fullWidth
                                                        label="TRANSPORT INFORMATION: OTHER"
                                                        defaultValue={userItem.other}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                                : null}

                                            <Grid item xs={12} >
                                                <TextField
                                                    multiline
                                                    variant="outlined" size="small"
                                                    fullWidth
                                                    label="MEMBERSHIP NO."
                                                    defaultValue={userItem.member_number}
                                                    InputProps={{
                                                        readOnly: true,
                                                        classes: { input: classes.input2 }
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={12} >
                                                <TextField
                                                    multiline
                                                    variant="outlined" size="small"
                                                    fullWidth
                                                    label="EMAIL"
                                                    defaultValue={userItem.email}
                                                    InputProps={{
                                                        readOnly: true,
                                                        classes: { input: classes.input3 }
                                                    }}
                                                />
                                            </Grid>
                                            {profile.cellNumber ?
                                            <Grid item xs={12} >
                                                <TextField
                                                    multiline
                                                    variant="outlined" size="small"
                                                    fullWidth
                                                    label="CELLPHONE NO."
                                                    defaultValue={profile.cellNumber }
                                                    InputLabelProps={{ shrink: true }}  
                                                    InputProps={{
                                                        readOnly: true,
                                                        classes: { input: classes.input3 }
                                                    }}
                                                />
                                            </Grid>
                                           : null}

                                        </Grid>

                                    </Box>
                                </Box>

                            </Container>
                        </Box>
{/* 
                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {userItem.isBillable ?
                                <Box display="flex" alignItems="center" justifyContent="center" style={{ color: 'red', width: 200, border: '1px solid AntiqueWhite', borderRadius: 6, float: 'left', backgroundColor: 'white' }} component="div">Remaining Credits:{GetRemainingCredits()}</Box> : null}
                        </Box> */}

                        <Box display="flex" alignItems="center" justifyContent="center" component="div">ICE-TAGS System v2.5</Box>
                        <Copyright sx={{}} />

                    </Paper>
                </ThemeProvider>

            </React.Fragment>

    );
};

export default Tag;
