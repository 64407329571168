import React, { useEffect, useState, useMemo } from 'react';
import {

    useRecoilState,

} from 'recoil';

import { useNavigate } from 'react-router-dom'
import Fab from '@mui/material/Fab';
import Button from '@mui/material/Button';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import Resizer from "react-image-file-resizer";
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
    update, validateTag, getUserByTag, addIceContactsToDevice,
    setIpPortToDevice, restartDevice, restoreFactoryDevice, deviceOff,
    deviceResetPassword, addIceNumbersToDevice, getDeviceIce,
    get_profile, refreshDevice
} from "../API/Agent";
import { cloneDeep } from 'lodash'
import IconButton from '@mui/material/IconButton';
import CloseIcon from "@material-ui/icons/Close";
import { ProfileStateChange, RefreshStateChange } from "../Atoms/ProfileAtom";
import Copyright from './Copyright';
import ProfileTags from './ProfileTags';
import GlobalContext from "../Context/Context";
import { Box, Tab, Tabs, Typography, Avatar, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListItemAvatar, Divider } from '@mui/material';
//icons
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import Delete from '@material-ui/icons/Delete';
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import Refresh from '@material-ui/icons/Refresh';
import Save from '@material-ui/icons/Save';
//import RestoreIcon from "@material-ui/icons/PermIdentity";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import CachedIcon from '@mui/icons-material/Cached';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import RestoreIcon from '@mui/icons-material/Restore';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import LockResetIcon from '@mui/icons-material/LockReset';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import SettingsSystemDaydreamIcon from '@mui/icons-material/SettingsSystemDaydream';

const theme = createTheme({
    palette: {
        primary: {
            light: '#757ce8',
            main: '#1976D2',
            dark: '#FFD700',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#fff',
        },
    },
});


const Profile = () => {
    const tableRef = React.createRef();
    const [tabIndex, setTabIndex] = useState(0);
    const [legend, setLegend] = useState([]);
    const { title, setTitle } = React.useContext(GlobalContext);
    const [open, setOpen] = React.useState(false);
    const [counter, setCounter] = useRecoilState(RefreshStateChange);
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [addTag, setaddTag] = React.useState(false);
    const [addDevice, setaddDevice] = React.useState(false);
    const [addDeviceNumbers, setAddDeviceNumbers] = React.useState(false);
    const [number1, setNumber1] = React.useState('');
    const [number2, setNumber2] = React.useState('');
    const [number3, setNumber3] = React.useState('');
    const [tagId, setTagId] = React.useState("");
    const [IdNum, setIdNum] = React.useState("");
    const [center, setCenter] = useState({});
    const [checked, setChecked] = React.useState(false);
    const [profile, setProfile] = useRecoilState(ProfileStateChange);
    const [firstname, setFirstName] = React.useState(profile.firstname);
    const [lastname, setLastName] = React.useState(profile.lastname);
    const [email, setEmail] = React.useState(profile.email);
    const [cellNumber, setCellNumber] = React.useState(profile.cellNumber);
    const [id_number, setId_Number] = React.useState(profile.id_number);
    const [password, setPassword] = React.useState(profile.password);
    const [confirm_password, setConfirmPassword] = React.useState("");
    //corporate details
    const [tabProfileHeader, setTabProfileHeader] = React.useState(profile.isCorperate ? "Corporate" : "Profile");
    const [corporateName, setCorporateName] = React.useState(profile.isCorperate ? profile.corporate.corporateName : '');
    const [corporateCell, setCorporateCell] = React.useState(profile.isCorperate ? profile.corporate.corporateCell : '');
    const [corporateEmail, setCorporateEmail] = React.useState(profile.isCorperate ? profile.corporate.corporateEmail : '');
    const rowsPerPage = 25;
    //device
    const [deviceId, setDeviceId] = React.useState("");
    const [old_deviceId, setOld_DeviceId] = React.useState("");
    const [deviceCellNumber, setDeviceCellNumber] = React.useState("");
    const [deviceMemberNumber, setDeviceMemberNumber] = React.useState("");
    const [deviceNickName, setDeviceNickName] = React.useState("");
    const [deviceActive, setDeviceActive] = React.useState(true);
    const [deviceHeader, setDeviceHeader] = React.useState("Add New Device");
    const [deleteDevice, setDeleteDevice] = React.useState(false);
    const [isModalMessage, SetIsModalMessage] = React.useState(false);
    let navigate = useNavigate();
    const [sosUrl, setSosUrl] = React.useState('https://res.cloudinary.com/resqtap/image/upload/v1680524352/sos_aywiyn.png');
    const handleDeviceManaging = (service, cellNumber, tagId) => {

        const messageType1 = {
            tagNumber: tagId,
            cellNumber: cellNumber,
        }
        const messageType2 = {
            cellNumber: cellNumber,
        }

        switch (service) {
            case "refresh":
                refreshDevice(messageType2).then((result) => {
                    if (result !== '—') {
                        alert("Device Refreshed");
                    } else {
                        alert("Device Refreshed Failed");
                    }
                });
                break;
            case "restart":
                restartDevice(messageType2).then((result) => {
                    if (result !== '—') {
                        alert("Device Restarted");
                    } else {
                        alert("Device Restart Failed");
                    }
                });
                break;
            case "restore":
                restoreFactoryDevice(messageType2).then((result) => {
                    if (result !== '—') {
                        alert("Device Restored to factory settings");
                    } else {
                        alert("Device Restore Failed");
                    }
                });
                break;
            case "off":
                deviceOff(messageType2).then((result) => {
                    if (result !== '—') {
                        alert("Device Off");
                    } else {
                        alert("Device Off Failed");
                    }
                });
                break;
            case "reset":
                deviceResetPassword(messageType2).then((result) => {
                    if (result !== '—') {
                        alert("Device Password Reset");
                    } else {
                        alert("Device Reset Failed");
                    }
                });
                break;
            case "ice":
                addIceContactsToDevice(messageType1).then((result) => {
                    if (result !== '—') {
                        alert("Added to Device ICE Contacts Added");
                    } else {
                        alert("Device ICE Failed");
                    }
                });
                break;
            case "ip":
                setIpPortToDevice(messageType2).then((result) => {
                    if (result !== '—') {
                        alert("Set IP and Port to Device");
                    } else {
                        alert("Device Setup Failed");
                    }
                });
                break;
        }

    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(), hour = d.getHours(), minute = d.getMinutes(), seconds = d.getSeconds();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;


        const theDate = [year, month, day].join('-') + " " + [hour, minute, seconds].join(':');
        return theDate;
    }

    function formatDate2(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(), hour = d.getHours(), minute = d.getMinutes(), seconds = d.getSeconds();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;


        const theDate = [year, month, day].join('-');
        return theDate;
    }

    const refreshPage = () => {
        window.location.reload();
    }
    const handleUpdateClickOpen = () => {
        setDeviceHeader("Update Device");
        setaddDevice(true);
    }
    const handleAddNewDevice = () => {
        setDeviceHeader("Add New Device");
        setaddDevice(true);
    }
    const handleDeleteDeviceAndClose = () => {

        let profileClone = cloneDeep(profile);
        const index = profileClone.devices.findIndex((d) => d.deviceId === deviceId);
        profileClone.devices.splice(index, 1);

        update(profileClone, profileClone._id).then((result) => {
            if (result !== '—') {

                setProfile(result);
                setDeleteDevice(false);
                setDeviceActive(true);
                setDeviceId("");
                setDeviceCellNumber("");
                setDeviceMemberNumber("");
                setDeviceNickName("");

            } else {
                //do something here placeholder
            }
        });
    }
    const handleDelClickOpen = () => {
        setDeleteDevice(true);
    }
    async function handleUpdateDeviceAndClose() {
        //add code here to add new record
        let profileClone = cloneDeep(profile);
        const index = profileClone.devices.findIndex((d) => d.deviceId === old_deviceId);
        profileClone.devices[index].nickName = deviceNickName;
        profileClone.devices[index].deviceId = deviceId;
        profileClone.devices[index].cellNumber = deviceCellNumber;
        profileClone.devices[index].member_number = deviceMemberNumber;
        profileClone.devices[index].active = true;

        let result = await update(profileClone, profileClone._id)
        if (result !== '—') {

            setProfile(result);
            setaddDevice(false);
            setDeviceActive(true);
            setDeviceId("");
            setDeviceCellNumber("");
            setDeviceMemberNumber("");
            setDeviceNickName("");
        }

    }


    const handleNumbersClose = () => {
        setAddDeviceNumbers(false);

    }

    const handleAddNumbersAndClose = (data) => {

        const message = {
            deviceId: deviceId,
            cellNumber: cellNumber,
            number1: number1,
            number2: number2,
            number3: number3
        }
        addIceNumbersToDevice(message).then((result) => {
            if (result !== '—') {
                setNumber1('');
                setNumber2('');
                setNumber3('');
                alert("Added to Device ICE Contacts Added");
            } else {
                alert("Device ICE Failed");
            }
        });

        setAddDeviceNumbers(false);
    }
    const handleMessageClose = () => {
        SetIsModalMessage(false);
    }
    const handleDeviceClose = () => {
        setaddDevice(false);
        setDeleteDevice(false);
    }
    const handleAddDeviceAndClose = () => {
        //add code here to add new record
        let profileClone = cloneDeep(profile);
        profileClone.devices.push({
            deviceId: deviceId,
            cellNumber: deviceCellNumber,
            member_number: deviceMemberNumber,
            active: true,
            activeDate: new Date()
        });
        update(profileClone, profileClone._id).then((result) => {
            if (result !== '—') {

                setProfile(result);
                setaddDevice(false);
                setDeviceActive(true);
                setDeviceId("");
                setDeviceCellNumber("");
                setDeviceMemberNumber("");
                setDeviceNickName("");
            } else {
                //do something here placeholder
            }
        });


    }

    useEffect(() => {
        async function fetchData() {
            get_profile().then((resp) => {
                setProfile({ ...resp });
                console.log("profile ", resp);
                navigate("/profile");

                if (profile.isCorperate) {
                    profile.devices.forEach(async (item, i) => {

                        if (item.geolocation !== null) {
                            setCenter({
                                lat: item.geolocation.coords.latitude,
                                lng: item.geolocation.coords.longitude
                            });
                        }

                    });


                    // profile.member_numbers.forEach(async (item, i) => {
                    //     await getUserByTag(item.member_number).then((result) => {
                    //         if (result !== null) {
                    //             setLegend(arr => [...arr, { name: result.names, member_number: result.member_number, photo: result.photo, geoloction: result.geoloction }]);

                    //         }
                    //     });
                    // });
                    profile.devices.forEach(async (item, i) => {

                        if (item !== null) {
                            setLegend(arr => [...arr, { name: item.nickName, member_number: item.cellNumber, photo: sosUrl, geoloction: item.geolocation }]);

                        }

                    });
                    if (typeof (profile._id) === "undefined") navigate("/login");
                    setProfile(profile);
                    setTitle("CORPORATE ACCOUNT");
                    if (counter % 2) {


                        refreshPage();
                    }
                    setCounter(counter + 1)
                }
                else {
                    getUserByTag(profile.member_numbers[0].member_number).then((result) => {
                        //console.log("first load result: ",result)
                        if (result !== null) {

                            setCenter({
                                lat: result.geoloction.coords.latitude,
                                lng: result.geoloction.coords.longitude
                            });
                        }
                    });


                    profile.member_numbers.forEach(async (item, i) => {
                        await getUserByTag(item.member_number).then((result) => {
                            if (result !== null) {
                                setLegend(arr => [...arr, { name: result.names, member_number: result.member_number, photo: result.photo, geoloction: result.geoloction }]);

                            }
                        });
                    });
                    profile.devices.forEach(async (item, i) => {

                        if (item !== null) {
                            setLegend(arr => [...arr, { name: item.nickName, member_number: item.cellNumber, photo: sosUrl, geoloction: item.geolocation }]);

                        }

                    });
                    if (typeof (profile._id) === "undefined") navigate("/login");
                    setProfile(profile);
                    setTitle("PRIVATE ACCOUNT");
                    if (counter % 2) {


                        refreshPage();
                    }
                    setCounter(counter + 1)
                }
            });
        }
        fetchData();

    }, [])

    const handleClickOpen = () => {
        setOpen(true);

    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (e) => {
        let isChecked = e.target.checked;
        setChecked(isChecked);
    }


    const handleAddNewTag = () => {
        setaddTag(true);
    }
    const handleTagClose = () => {
        setaddTag(false);
    }
    const handleAddTagAndClose = () => {
        //add
        validateTag(tagId, IdNum, profile._id).then((result) => {
            if (result === true) {

                let profileClone = cloneDeep(profile)
                let member = new Object();
                member.member_number = tagId;
                member.id_number = IdNum;
                const members = profileClone.member_numbers.find(
                    element => {
                        if (element.member_number === tagId) {
                            return true;
                        }
                    });
                if (members === undefined) {
                    profileClone.member_numbers.push(member);
                    try {
                        //console.log(profileClone);
                        update(profileClone, profile._id).then((response) => {

                            if (response !== '—') {

                                setProfile(response);
                                setOpenSuccess(true);
                            } else {
                                //do something here placeholder
                            }
                        });
                    } catch (error) {
                        console.log({ ...error })
                    }
                } else {
                    alert("Sorry you already added this tag.")
                }
            }
            else {
                alert("no tag found")
            }
        });

        setaddTag(false);
    }


    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const pass = data.get('password');
        const cpass = data.get('confirm_password');
        if (pass !== cpass) {

            alert("Sorry your password must match")
            return;
        }

        // eslint-disable-next-line no-console
        let userRegister =
        {
            email: data.get('email'),
            password: data.get('password'),
            firstname: data.get('firstName'),
            lastname: data.get('lastName'),
            id_number: data.get('id_number'),
            cellNumber: data.get('cellNumber'),
            allowmarketing: checked,
            role: profile.role,
            member_numbers: profile.member_numbers,

        };

        try {
            // console.log(userRegister);
            update(userRegister, profile._id).then((response) => {

                if (response !== '—') {

                    setProfile(response);
                    handleClickOpen(); //fix this

                } else {
                    //do something here placeholder
                }
            });


        } catch (error) {
            console.log({ ...error })

        }

    };

    const handleTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
        if (newTabIndex === 3) {
            SetIsModalMessage(true);
        }
    };

    const containerStyle = {
        width: '100%',
        height: '100vh'
    };



    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAPXz3LrY2o_B9VcErZHcahOqjURhTUltU"
    })

    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
        //console.log("map legend: ", legend)
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        const legendX = document.getElementById("legend");
        // console.log("legend: ",legend)
        // console.log("profile member_numbers", profile.member_numbers);
        profile.member_numbers.forEach(async (item, i) => {
            // console.log("item: ",item.member_number)
            // console.log("i: ",i)

            await getUserByTag(item.member_number).then((result) => {


                //  console.log("result: ",result)
                if (result !== null) {
                    const icon = {
                        url: result.photo,
                        // This marker is 20 pixels wide by 32 pixels high.
                        size: new window.google.maps.Size(50, 50),
                        scaledSize: new window.google.maps.Size(50, 50),
                        origin: new window.google.maps.Point(0, 0), // origin
                        anchor: new window.google.maps.Point(0, 0) // anchor
                    };
                    new window.google.maps.Marker({
                        position: { lat: result.geoloction.coords.latitude, lng: result.geoloction.coords.longitude },
                        map,
                        title: result.member_number,
                        icon: icon,// result.photo
                        animation: window.google.maps.Animation.DROP,
                    });



                    // const div = document.createElement("div");

                    //     div.innerHTML = '<div style="display: flex;align-items: center;height:35px"><img src="' +  result.photo + '" style="width:32px;height:32px;"> ' +'<div style="margin-left: 2px;color: grey;display: inline-block;">'+result.member_number+'</div></div>' ;
                    // legend.appendChild(div);

                }
            });

        });
        profile.devices.forEach(async (item, i) => {
            // console.log("item: ",item.member_number)
            // console.log("i: ",i)

            //await getUserByTag(item.member_number).then((result) => {


            //  console.log("result: ",result)
            if (item !== null) {
                const icon = {
                    url: sosUrl,
                    // This marker is 20 pixels wide by 32 pixels high.
                    size: new window.google.maps.Size(50, 50),
                    scaledSize: new window.google.maps.Size(50, 50),
                    origin: new window.google.maps.Point(0, 0), // origin
                    anchor: new window.google.maps.Point(0, 0) // anchor
                };
                new window.google.maps.Marker({
                    position: { lat: item.geolocation.coords.latitude, lng: item.geolocation.coords.longitude },
                    map,
                    title: item.member_number,
                    icon: icon,// result.photo
                    animation: window.google.maps.Animation.DROP,
                });



                // const div = document.createElement("div");

                //     div.innerHTML = '<div style="display: flex;align-items: center;height:35px"><img src="' +  result.photo + '" style="width:32px;height:32px;"> ' +'<div style="margin-left: 2px;color: grey;display: inline-block;">'+result.member_number+'</div></div>' ;
                // legend.appendChild(div);

            }
            // });

        });
        //const bounds = new window.google.maps.LatLngBounds(cen);
        // map.fitBounds(bounds);
        setMap(map)
        map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(legendX);
    }, [])

    const handleNavigate = (node) => {

        const bounds = new window.google.maps.LatLngBounds({ lat: node.geoloction.coords.latitude, lng: node.geoloction.coords.longitude });
        map.fitBounds(bounds);
    }

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    const mapStructure = (nodes) => {
        if (nodes) {
            return nodes.map((node, i) => (
                <>
                    <ListItem alignItems="flex-start" key={i} onClick={() => handleNavigate(node)}>
                        <ListItemAvatar>
                            <Avatar alt={node.member_number} src={node.photo} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={node.member_number}
                            secondary={
                                <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                    {node.name}
                                </Typography>
                            }
                        />
                    </ListItem>

                </>
            ));
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Box>
                {!profile.isCorperate ? (<>
                    <Box>
                        <Tabs value={tabIndex} onChange={handleTabChange}>
                            <Tab label={tabProfileHeader} />

                            <Tab label="Tags" />
                            <Tab label="Geolocation" />
                            <Tab label="Devices" />


                        </Tabs>
                    </Box>
                    <Box sx={{ padding: 2 }}>
                        {tabIndex === 0 && (
                            <Box>
                                <Box
                                    sx={{
                                        marginTop: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'left',
                                    }}
                                >
                                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>


                                        <Grid container spacing={2} item sm={4}>
                                            {profile.isCorperate ? (
                                                <>

                                                    <Grid item sm={12}>

                                                        <TextField
                                                            autoComplete="given-name"
                                                            name="corporateName"
                                                            required
                                                            fullWidth
                                                            id="corporateName"
                                                            label="Corporate Name"
                                                            value={corporateName}
                                                            autoFocus
                                                            onChange={e => setCorporateName(e.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item sm={12}>

                                                        <TextField
                                                            autoComplete="given-name"
                                                            name="corporateCell"
                                                            required
                                                            fullWidth
                                                            id="corporateCell"
                                                            label="Corporate Cell"
                                                            value={corporateCell}

                                                            onChange={e => setCorporateCell(e.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item sm={12}>

                                                        <TextField
                                                            autoComplete="given-name"
                                                            name="corporateEmail"
                                                            required
                                                            fullWidth
                                                            id="corporateEmail"
                                                            label="Corporate Email"
                                                            value={corporateEmail}

                                                            onChange={e => setCorporateEmail(e.target.value)}
                                                        />
                                                    </Grid>

                                                </>) : null}
                                            {profile.isCorperate ? (
                                                <>
                                                    <Grid item sm={12}>
                                                        <TextField
                                                            autoComplete="given-name"
                                                            name="firstName"
                                                            required
                                                            fullWidth
                                                            id="firstName"
                                                            label="First Name"
                                                            value={firstname}

                                                            onChange={e => setFirstName(e.target.value)}
                                                        />
                                                    </Grid>
                                                </>) :
                                                (<>
                                                    <Grid item sm={12}>
                                                        <TextField
                                                            autoComplete="given-name"
                                                            name="firstName"
                                                            required
                                                            fullWidth
                                                            id="firstName"
                                                            label="First Name"
                                                            value={firstname}
                                                            autoFocus
                                                            onChange={e => setFirstName(e.target.value)}
                                                        />
                                                    </Grid>
                                                </>)}

                                            <Grid item sm={12}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    id="lastName"
                                                    label="Last Name"
                                                    name="lastName"
                                                    autoComplete="family-name"
                                                    value={lastname}
                                                    onChange={e => setLastName(e.target.value)}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    id="id_number"
                                                    label="ID Number"
                                                    name="id_number"
                                                    autoComplete="id_number"
                                                    value={id_number}
                                                    onChange={e => setId_Number(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    InputProps={{
                                                        readOnly: false,
                                                    }}
                                                    id="cellNumber"
                                                    label="Cell Number"
                                                    name="cellNumber"
                                                    autoComplete="cellNumber"
                                                    value={cellNumber}
                                                    onChange={e => setCellNumber(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    id="email"
                                                    label="Email Address / Username"
                                                    name="email"
                                                    autoComplete="email"
                                                    value={email}
                                                    onChange={e => setEmail(e.target.value)}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    name="password"
                                                    label="Password"
                                                    type="password"
                                                    id="password"
                                                    autoComplete="new-password"
                                                    value={password}
                                                    onChange={e => setPassword(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    name="confirm_password"
                                                    label="Confirm Password"
                                                    type="password"
                                                    id="confirm_password"
                                                    autoComplete="new-password"
                                                    value={confirm_password}
                                                    onChange={e => setConfirmPassword(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    control={<Checkbox value="allowExtraEmails" color="primary" checked={checked} onChange={handleChange} />}
                                                    label="I want to receive inspiration, marketing promotions and updates via email."
                                                />
                                            </Grid>
                                        </Grid>
                                        <Button
                                            type="submit"

                                            variant="contained"

                                            sx={{ mt: 3, mb: 2, }}
                                        >
                                            SUBMIT
                                        </Button>
                                        <Dialog
                                            open={open}
                                            onClose={handleClose}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
                                                {"ICE-TAGS User Updated"}
                                            </DialogTitle>
                                            <DialogContent>
                                                <DialogContentText id="alert-dialog-description">
                                                    Your profile was successfully updated.
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                {/* <Button onClick={handleClose}>Disagree</Button> */}
                                                <Button onClick={handleClose} autoFocus>
                                                    OK
                                                </Button>
                                            </DialogActions>
                                        </Dialog>

                                    </Box>
                                </Box>
                            </Box>
                        )}
                        {tabIndex === 1 && (
                            <Box>
                                <AppBar position="static" color="secondary" elevation={3} >
                                    <Toolbar variant="dense">
                                        <Box component='div' sx={{ display: 'flex', justifyContent: 'space-between', width: "100%" }}>
                                            {/* Logo or actoin button */}
                                            <Box >
                                                <Tooltip title="Add New Tag">
                                                    <Fab onClick={handleAddNewTag} size="small" color="primary" aria-label="add">
                                                        <AddIcon />
                                                    </Fab>
                                                </Tooltip>
                                            </Box>
                                            {/* Heading or links */}
                                            <Box>
                                                <Typography variant="h6" color="inherit" component="div">

                                                </Typography>
                                            </Box>
                                        </Box>


                                    </Toolbar>
                                </AppBar>
                                <Box display="flex" flexWrap="wrap" justifyContent="left" m={1} p={1} sx={{ border: 1, borderColor: 'secondary.main' }}>
                                    <ProfileTags />
                                </Box>
                            </Box>
                        )}
                        {tabIndex === 2 && (
                            <Box>
                                {isLoaded ? (
                                    <Box>
                                        <Box id="legend" component="div" sx={{ p: 2, borderRadius: 2, border: '1px solid lightgrey', boxShadow: '0px 5px 5px lightgrey', backgroundColor: 'rgba(255, 255, 255)' }}>
                                            <Typography variant="h5" color="inherit" component="div" sx={{ marginTop: -2, marginBottom: 1 }}> REGISTERED TAGS</Typography>
                                            <List>
                                                {mapStructure(legend)}
                                                {/* {legend.map((item, index) => {
                                            return (

                                                <ListItem alignItems="flex-start">
                                                <ListItemAvatar>
                                                  <Avatar alt={item.member_number} src={item.photo} />
                                                </ListItemAvatar>
                                                <ListItemText
                                                  primary={item.member_number}
                                                  secondary={
                                                      <Typography
                                                        sx={{ display: 'inline' }}
                                                        component="span"
                                                        variant="body2"
                                                        color="text.primary"
                                                      >
                                                        name
                                                      </Typography>
                                                  }
                                                />
                                              </ListItem>
                            

                                            )
                                        })} */}
                                            </List>
                                        </Box>
                                        <GoogleMap
                                            mapContainerStyle={containerStyle}
                                            center={center}
                                            zoom={16}
                                            onLoad={onLoad}
                                            onUnmount={onUnmount}

                                        >

                                        </GoogleMap>
                                    </Box>
                                ) : <></>}
                            </Box>
                        )}
                        {tabIndex === 3 && (
                            <Box>
                                <AppBar position="static" color="secondary" elevation={3} >
                                    <Toolbar variant="dense">
                                        <Box component='div' sx={{ display: 'flex', justifyContent: 'space-between', width: "100%" }}>
                                            {/* Logo or actoin button */}
                                            <Box >
                                                <Tooltip title="Add New Device">
                                                    <Fab onClick={handleAddNewDevice} size="small" color="primary" aria-label="add">
                                                        <AddIcon />
                                                    </Fab>
                                                </Tooltip>
                                            </Box>
                                            {/* Heading or links */}
                                            <Box>
                                                <Typography variant="h6" color="inherit" component="div">

                                                </Typography>

                                            </Box>
                                        </Box>


                                    </Toolbar>
                                </AppBar>
                                <Box display="flex" flexWrap="wrap" justifyContent="left" m={1} p={1} sx={{ border: 1, borderColor: 'secondary.main' }}>

                                    <MaterialTable
                                        title="Devices"
                                        tableRef={tableRef}
                                        style={{ width: "100%" }}
                                        columns={[
                                            { title: "Device Name", field: "nickName", width: "10%" },
                                            { title: "Device ID", field: "deviceId", width: "10%" },
                                            { title: "Cell Number", field: "cellNumber", width: "10%" },
                                            { title: "Member Number", field: "member_number", width: "10%" },
                                            { title: "Active", field: "active", width: "10%" },
                                            { title: "Date Created", field: "insertDate", width: "20%", render: (rowData) => formatDate(rowData.insertDate) },

                                        ]}
                                        data={profile.devices}
                                        components={{
                                            Toolbar: props => (
                                                <div>
                                                    <MTableToolbar {...props} />
                                                    <div style={{ padding: '0px 10px' }}>
                                                        <Tooltip title="Add New User">
                                                            <Fab onClick={handleAddNewDevice} size="small" color="primary" aria-label="add">
                                                                <AddIcon />
                                                            </Fab>
                                                        </Tooltip>

                                                    </div>
                                                </div>
                                            ),
                                        }}
                                        actions={[
                                            {
                                                width: 10,
                                                icon: SettingsSystemDaydreamIcon,
                                                tooltip: 'Configure Server on Device',
                                                onClick: (event, rowData) => {
                                                    setDeviceId(rowData.deviceId);

                                                    handleDeviceManaging('ip', rowData.cellNumber, rowData.member_number);
                                                }
                                            },
                                            {
                                                width: 10,
                                                icon: LocalPoliceIcon,
                                                tooltip: 'Send ICE Contacts to Device',
                                                onClick: (event, rowData) => {

                                                    setDeviceId(rowData.deviceId);
                                                    handleDeviceManaging('ice', rowData.cellNumber, rowData.member_number);


                                                }
                                            },
                                            {
                                                width: 10,
                                                icon: RestoreIcon,
                                                tooltip: 'Restore To Factory Settings',
                                                onClick: (event, rowData) => {
                                                    setDeviceId(rowData.deviceId);
                                                    handleDeviceManaging('restore', rowData.cellNumber, rowData.member_number);
                                                }
                                            },
                                            {
                                                width: 10,
                                                icon: RestartAltIcon,
                                                tooltip: 'Restart Device',
                                                onClick: (event, rowData) => {
                                                    setDeviceId(rowData.deviceId);
                                                    handleDeviceManaging('restart', rowData.cellNumber, rowData.member_number);
                                                }
                                            },
                                            {
                                                width: 10,
                                                icon: CachedIcon,
                                                tooltip: 'Set Device Refresh Rate',
                                                onClick: (event, rowData) => {
                                                    setDeviceId(rowData.deviceId);
                                                    handleDeviceManaging('refresh', rowData.cellNumber, rowData.member_number);
                                                }
                                            },
                                            {
                                                width: 10,
                                                icon: PowerSettingsNewIcon,
                                                tooltip: 'Turn Device off',
                                                onClick: (event, rowData) => {
                                                    setDeviceId(rowData.deviceId);
                                                    handleDeviceManaging('off', rowData.cellNumber, rowData.member_number);
                                                }
                                            },
                                            {
                                                width: 10,
                                                icon: LockResetIcon,
                                                tooltip: 'Reset Password to factory on Device',
                                                onClick: (event, rowData) => {
                                                    setDeviceId(rowData.deviceId);
                                                    handleDeviceManaging('reset', rowData.cellNumber, rowData.member_number);
                                                }
                                            },
                                            {
                                                width: 10,
                                                icon: Save,
                                                tooltip: 'Update Device Details',
                                                onClick: (event, rowData) => {
                                                    setOld_DeviceId(rowData.deviceId);
                                                    setDeviceId(rowData.deviceId);
                                                    setDeviceCellNumber(rowData.cellNumber);
                                                    setDeviceMemberNumber(rowData.member_number);
                                                    setDeviceNickName(rowData.nickName);
                                                    handleUpdateClickOpen();
                                                }
                                            },
                                            {
                                                width: 10,
                                                icon: Delete,
                                                tooltip: 'Delete Device',
                                                onClick: (event, rowData) => {
                                                    setDeviceId(rowData.deviceId);
                                                    handleDelClickOpen();
                                                }
                                            }

                                        ]}
                                        options={{
                                            pageSize: rowsPerPage,
                                            pageSizeOptions: [25, 50, 100],
                                            actionsColumnIndex: -1,
                                            search: true,
                                            icon: "RestoreIcon",
                                            tooltip: "Refresh Data",
                                            headerStyle: {
                                                backgroundColor: "#ff0000",
                                                color: "#FFFFFF",
                                            },
                                            sorting: true,
                                            onClick: () => tableRef.current && tableRef.current.onQueryChange()
                                        }}

                                    />
                                </Box>
                            </Box>
                        )}
                    </Box>
                </>
                ) : <>
                    <Box>
                        <Tabs value={tabIndex} onChange={handleTabChange}>
                            <Tab label={tabProfileHeader} />
                            <Tab label="Geolocation" />
                            <Tab label="Devices" />




                        </Tabs>
                    </Box>
                    <Box sx={{ padding: 2 }}>
                        {tabIndex === 0 && (
                            <Box>
                                <Box
                                    sx={{
                                        marginTop: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'left',
                                    }}
                                >
                                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>


                                        <Grid container spacing={2} item sm={4}>
                                            {profile.isCorperate ? (
                                                <>

                                                    <Grid item sm={12}>

                                                        <TextField
                                                            autoComplete="given-name"
                                                            name="corporateName"
                                                            required
                                                            fullWidth
                                                            id="corporateName"
                                                            label="Corporate Name"
                                                            value={corporateName}
                                                            autoFocus
                                                            onChange={e => setCorporateName(e.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item sm={12}>

                                                        <TextField
                                                            autoComplete="given-name"
                                                            name="corporateCell"
                                                            required
                                                            fullWidth
                                                            id="corporateCell"
                                                            label="Corporate Cell"
                                                            value={corporateCell}

                                                            onChange={e => setCorporateCell(e.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item sm={12}>

                                                        <TextField
                                                            autoComplete="given-name"
                                                            name="corporateEmail"
                                                            required
                                                            fullWidth
                                                            id="corporateEmail"
                                                            label="Corporate Email"
                                                            value={corporateEmail}

                                                            onChange={e => setCorporateEmail(e.target.value)}
                                                        />
                                                    </Grid>

                                                </>) : null}
                                            {profile.isCorperate ? (
                                                <>
                                                    <Grid item sm={12}>
                                                        <TextField
                                                            autoComplete="given-name"
                                                            name="firstName"
                                                            required
                                                            fullWidth
                                                            id="firstName"
                                                            label="First Name"
                                                            value={firstname}

                                                            onChange={e => setFirstName(e.target.value)}
                                                        />
                                                    </Grid>
                                                </>) :
                                                (<>
                                                    <Grid item sm={12}>
                                                        <TextField
                                                            autoComplete="given-name"
                                                            name="firstName"
                                                            required
                                                            fullWidth
                                                            id="firstName"
                                                            label="First Name"
                                                            value={firstname}
                                                            autoFocus
                                                            onChange={e => setFirstName(e.target.value)}
                                                        />
                                                    </Grid>
                                                </>)}

                                            <Grid item sm={12}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    id="lastName"
                                                    label="Last Name"
                                                    name="lastName"
                                                    autoComplete="family-name"
                                                    value={lastname}
                                                    onChange={e => setLastName(e.target.value)}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    id="id_number"
                                                    label="ID Number"
                                                    name="id_number"
                                                    autoComplete="id_number"
                                                    value={id_number}
                                                    onChange={e => setId_Number(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    InputProps={{
                                                        readOnly: false,
                                                    }}
                                                    id="cellNumber"
                                                    label="Cell Number"
                                                    name="cellNumber"
                                                    autoComplete="cellNumber"
                                                    value={cellNumber}
                                                    onChange={e => setCellNumber(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    id="email"
                                                    label="Email Address / Username"
                                                    name="email"
                                                    autoComplete="email"
                                                    value={email}
                                                    onChange={e => setEmail(e.target.value)}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    name="password"
                                                    label="Password"
                                                    type="password"
                                                    id="password"
                                                    autoComplete="new-password"
                                                    value={password}
                                                    onChange={e => setPassword(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    name="confirm_password"
                                                    label="Confirm Password"
                                                    type="password"
                                                    id="confirm_password"
                                                    autoComplete="new-password"
                                                    value={confirm_password}
                                                    onChange={e => setConfirmPassword(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    control={<Checkbox value="allowExtraEmails" color="primary" checked={checked} onChange={handleChange} />}
                                                    label="I want to receive inspiration, marketing promotions and updates via email."
                                                />
                                            </Grid>
                                        </Grid>
                                        <Button
                                            type="submit"

                                            variant="contained"

                                            sx={{ mt: 3, mb: 2, }}
                                        >
                                            SUBMIT
                                        </Button>
                                        <Dialog
                                            open={open}
                                            onClose={handleClose}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
                                                {"ICE-TAGS User Updated"}
                                            </DialogTitle>
                                            <DialogContent>
                                                <DialogContentText id="alert-dialog-description">
                                                    Your profile was successfully updated.
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                {/* <Button onClick={handleClose}>Disagree</Button> */}
                                                <Button onClick={handleClose} autoFocus>
                                                    OK
                                                </Button>
                                            </DialogActions>
                                        </Dialog>

                                    </Box>
                                </Box>
                            </Box>
                        )}
                        {tabIndex === 1 && (
                            <Box>
                                {isLoaded ? (
                                    <Box>
                                        <Box id="legend" component="div" sx={{ p: 2, borderRadius: 2, border: '1px solid lightgrey', boxShadow: '0px 5px 5px lightgrey', backgroundColor: 'rgba(255, 255, 255)' }}>
                                            <Typography variant="h5" color="inherit" component="div" sx={{ marginTop: -2, marginBottom: 1 }}> REGISTERED TAGS</Typography>
                                            <List>
                                                {mapStructure(legend)}
                                                {/* {legend.map((item, index) => {
                                            return (

                                                <ListItem alignItems="flex-start">
                                                <ListItemAvatar>
                                                  <Avatar alt={item.member_number} src={item.photo} />
                                                </ListItemAvatar>
                                                <ListItemText
                                                  primary={item.member_number}
                                                  secondary={
                                                      <Typography
                                                        sx={{ display: 'inline' }}
                                                        component="span"
                                                        variant="body2"
                                                        color="text.primary"
                                                      >
                                                        name
                                                      </Typography>
                                                  }
                                                />
                                              </ListItem>
                            

                                            )
                                        })} */}
                                            </List>
                                        </Box>
                                        <GoogleMap
                                            mapContainerStyle={containerStyle}
                                            center={center}
                                            zoom={16}
                                            onLoad={onLoad}
                                            onUnmount={onUnmount}

                                        >

                                        </GoogleMap>
                                    </Box>
                                ) : <></>}
                            </Box>
                        )}
                        {tabIndex === 2 && (
                            <Box>
                                <AppBar position="static" color="secondary" elevation={3} >
                                    <Toolbar variant="dense">
                                        <Box component='div' sx={{ display: 'flex', justifyContent: 'space-between', width: "100%" }}>
                                            {/* Logo or actoin button */}
                                            <Box >
                                                <Tooltip title="Add New Device">
                                                    <Fab onClick={handleAddNewDevice} size="small" color="primary" aria-label="add">
                                                        <AddIcon />
                                                    </Fab>
                                                </Tooltip>
                                            </Box>
                                            {/* Heading or links */}
                                            <Box>
                                                <Typography variant="h6" color="inherit" component="div">

                                                </Typography>
                                            </Box>
                                        </Box>


                                    </Toolbar>
                                </AppBar>
                                <Box display="flex" flexWrap="wrap" justifyContent="left" m={1} p={1} sx={{ border: 1, borderColor: 'secondary.main' }}>
                                    <MaterialTable
                                        title="Devices"
                                        tableRef={tableRef}
                                        style={{ width: "100%" }}
                                        columns={[
                                            { title: "Device Name", field: "nickName", width: "10%" },
                                            { title: "Device ID", field: "deviceId", width: "10%" },
                                            { title: "Cell Number", field: "cellNumber", width: "10%" },
                                            { title: "Member Number", field: "member_number", width: "10%" },
                                            { title: "Active", field: "active", width: "10%" },
                                            { title: "Date Created", field: "insertDate", width: "20%" },

                                        ]}
                                        data={profile.devices}
                                        components={{
                                            Toolbar: props => (
                                                <div>
                                                    <MTableToolbar {...props} />
                                                    <div style={{ padding: '0px 10px' }}>
                                                        <Tooltip title="Add New User">
                                                            <Fab onClick={handleAddNewDevice} size="small" color="primary" aria-label="add">
                                                                <AddIcon />
                                                            </Fab>
                                                        </Tooltip>

                                                    </div>
                                                </div>
                                            ),
                                        }}
                                        actions={[
                                            {
                                                width: 10,
                                                icon: SettingsSystemDaydreamIcon,
                                                tooltip: 'Configure Server on Device',
                                                onClick: (event, rowData) => {
                                                    setDeviceId(rowData.deviceId);
                                                    handleDeviceManaging('ip', rowData.cellNumber, rowData.member_number);
                                                }
                                            },
                                            {
                                                width: 10,
                                                icon: LocalPoliceIcon,
                                                tooltip: 'Send ICE Contacts to Device',
                                                onClick: (event, rowData) => {
                                                    if (profile.isCorperate) {
                                                        setDeviceId(rowData.deviceId);
                                                        setCellNumber(rowData.cellNumber);
                                                        getDeviceIce(rowData.deviceId).then((res) => {
                                                            if (res === null) {
                                                                setNumber1("");
                                                                setNumber2("");
                                                                setNumber3("");
                                                                setAddDeviceNumbers(true);
                                                            } else {
                                                                setNumber1(res.ice1);
                                                                setNumber2(res.ice2);
                                                                setNumber3(res.ice3);
                                                                setAddDeviceNumbers(true);
                                                            }


                                                        }).catch((err) => {
                                                            console.log(err);
                                                        });

                                                    } else {
                                                        setDeviceId(rowData.deviceId);
                                                        handleDeviceManaging('ice', rowData.cellNumber, rowData.member_number);
                                                    }
                                                }
                                            },
                                            {
                                                width: 10,
                                                icon: RestoreIcon,
                                                tooltip: 'Restore To Factory Settings',
                                                onClick: (event, rowData) => {
                                                    setDeviceId(rowData.deviceId);
                                                    handleDeviceManaging('restore', rowData.cellNumber, rowData.member_number);
                                                }
                                            },
                                            {
                                                width: 10,
                                                icon: RestartAltIcon,
                                                tooltip: 'Restart Device',
                                                onClick: (event, rowData) => {
                                                    setDeviceId(rowData.deviceId);
                                                    handleDeviceManaging('restart', rowData.cellNumber, rowData.member_number);
                                                }
                                            },
                                            {
                                                width: 10,
                                                icon: CachedIcon,
                                                tooltip: 'Set Device Refresh Rate',
                                                onClick: (event, rowData) => {
                                                    setDeviceId(rowData.deviceId);
                                                    handleDeviceManaging('refresh', rowData.cellNumber, rowData.member_number);
                                                }
                                            },
                                            {
                                                width: 10,
                                                icon: PowerSettingsNewIcon,
                                                tooltip: 'Turn Device off',
                                                onClick: (event, rowData) => {
                                                    setDeviceId(rowData.deviceId);
                                                    handleDeviceManaging('off', rowData.cellNumber, rowData.member_number);
                                                }
                                            },
                                            {
                                                width: 10,
                                                icon: LockResetIcon,
                                                tooltip: 'Reset Password to factory on Device',
                                                onClick: (event, rowData) => {
                                                    setDeviceId(rowData.deviceId);
                                                    handleDeviceManaging('reset', rowData.cellNumber, rowData.member_number);
                                                }
                                            },
                                            {
                                                width: 10,
                                                icon: Save,
                                                tooltip: 'Update Device Details',
                                                onClick: (event, rowData) => {
                                                    setOld_DeviceId(rowData.deviceId);
                                                    setDeviceId(rowData.deviceId);
                                                    setDeviceCellNumber(rowData.cellNumber);
                                                    setDeviceMemberNumber(rowData.member_number);
                                                    setDeviceNickName(rowData.nickName);
                                                    handleUpdateClickOpen();
                                                }
                                            },
                                            {
                                                width: 10,
                                                icon: Delete,
                                                tooltip: 'Delete Device',
                                                onClick: (event, rowData) => {
                                                    setDeviceId(rowData.deviceId);
                                                    handleDelClickOpen();
                                                }
                                            }

                                        ]}
                                        options={{
                                            pageSize: rowsPerPage,
                                            pageSizeOptions: [25, 50, 100],
                                            actionsColumnIndex: -1,
                                            search: true,
                                            icon: "RestoreIcon",
                                            tooltip: "Refresh Data",
                                            headerStyle: {
                                                backgroundColor: "#ff0000",
                                                color: "#FFFFFF",
                                            },
                                            sorting: true,
                                            onClick: () => tableRef.current && tableRef.current.onQueryChange()
                                        }}

                                    />
                                </Box>
                            </Box>
                        )}
                    </Box>
                </>}

            </Box>
            <Container component="main" maxWidth="100%">
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'left',
                    }}
                >
                    <Copyright sx={{ mt: 5 }} />
                </Box>
            </Container>
            <Dialog open={addTag} onClose={handleTagClose} sx={{ textAlign: "center" }}>
                <DialogTitle>ADD ICE-TAGS</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please add the Tag-ID and your ID and we will add your new tag to your profile.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        onChange={e => setTagId(e.target.value)}
                        margin="dense"
                        id="TagId"
                        label="Tag ID"
                        type="text"
                        fullWidth
                        value={tagId}
                        variant="standard"
                    />
                    <TextField

                        onChange={e => setIdNum(e.target.value)}
                        margin="dense"
                        id="IdNum"
                        label="ID Number"
                        type="text"
                        fullWidth
                        value={IdNum}
                        variant="standard"
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleTagClose}>Cancel</Button>
                    <Button onClick={handleAddTagAndClose}>Add</Button>
                    {/* sendUpdate(rowData); */}
                </DialogActions>
            </Dialog>
            <Dialog open={openSuccess} onClose={() => setOpen(false)}>
                <DialogTitle>
                    <Grid container justify="space-between" alignItems="center">
                        <Typography variant="div">ICE-TAGS ADD</Typography>
                        <IconButton onClick={() => setOpenSuccess(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You have successfully sent a message to the tag owner, the person will activate your request for tag number: {tagId}. Upon recieving a validation email. You will only see the tag on your browser once the Tag owner allows it.
                    </DialogContentText>
                </DialogContent>
            </Dialog>


            <Dialog open={addDevice} onClose={handleDeviceClose} sx={{ textAlign: "center" }}>
                <DialogTitle>{deviceHeader}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {deviceHeader === "Add New Device" ? "Please add the ID from your container, Member Number and Device Cell Number and we will add your new device to your profile." : "Please update the ID from your container, Member Number and Device Cell Number and we will update your device to your profile."}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        onChange={e => setDeviceNickName(e.target.value)}
                        margin="dense"
                        id="DeviceNickName"
                        label="Device Name"
                        type="text"
                        fullWidth
                        value={deviceNickName}
                        variant="standard"
                    />
                    <TextField
                        onChange={e => setDeviceId(e.target.value)}
                        margin="dense"
                        id="DeviceId"
                        label="Device ID"
                        type="text"
                        fullWidth
                        value={deviceId}
                        variant="standard"
                    />
                    <TextField

                        onChange={e => setDeviceCellNumber(e.target.value)}
                        margin="dense"
                        id="DeviceCellNumber"
                        label="Cell Number"
                        type="text"
                        fullWidth
                        value={deviceCellNumber}
                        variant="standard"
                    />
                    <TextField

                        onChange={e => setDeviceMemberNumber(e.target.value)}
                        margin="dense"
                        id="MemberNumber"
                        label="Member Number"
                        type="text"
                        fullWidth
                        value={deviceMemberNumber}
                        variant="standard"
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeviceClose}>Cancel</Button>
                    {deviceHeader === "Add New Device" ? <Button onClick={handleAddDeviceAndClose}>Add</Button> : <Button onClick={handleUpdateDeviceAndClose}>Update</Button>}

                    {/* sendUpdate(rowData); */}
                </DialogActions>
            </Dialog>

            <Dialog open={addDeviceNumbers} onClose={handleDeviceClose} sx={{ textAlign: "center" }}>
                <DialogTitle>{"Add your 3 ICE numbers here"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {""}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        onChange={e => setNumber1(e.target.value)}
                        margin="dense"
                        id="number1"
                        label="ICE Number 1"
                        type="text"
                        fullWidth
                        value={number1}
                        variant="standard"
                    />
                    <TextField

                        onChange={e => setNumber2(e.target.value)}
                        margin="dense"
                        id="number2"
                        label="ICE Number 2"
                        type="text"
                        fullWidth
                        value={number2}
                        variant="standard"
                    />
                    <TextField

                        onChange={e => setNumber3(e.target.value)}
                        margin="dense"
                        id="number3"
                        label="ICE Number 3"
                        type="text"
                        fullWidth
                        value={number3}
                        variant="standard"
                    />


                </DialogContent>
                <DialogActions>
                    <Button onClick={handleNumbersClose}>Cancel</Button>
                    <Button onClick={handleAddNumbersAndClose}>Add</Button>
                </DialogActions>
            </Dialog>


            <Dialog open={deleteDevice} onClose={handleDeviceClose} sx={{ textAlign: "center" }}>
                <DialogTitle>Delete Device ID {deviceId}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to proceed?
                    </DialogContentText>


                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeviceClose}>No</Button>
                    <Button onClick={handleDeleteDeviceAndClose}>Yes</Button>

                    {/* sendUpdate(rowData); */}
                </DialogActions>
            </Dialog>

            <Dialog open={isModalMessage} onClose={handleMessageClose} sx={{ textAlign: "left" }}>
                <DialogTitle>How to change ICE Contact numbers {deviceId}</DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        <>
                            <ul>
                                <li>
                                    Click on the “TAGS” Menu
                                </li>
                                <li>
                                    Click “Update Tag” at the bottom of the Tag
                                </li>
                                <li>
                                    Edit the necessary ICE Contact Information
                                </li>
                                <li>
                                    Click “UPDATE MEMBER” at the bottom
                                </li>
                                <li>
                                    Navigate back to the Profile Page
                                </li>
                                <li>
                                    Click on the “Devices” Menu
                                </li>
                                <li>
                                    Situated on the right hand side of the page, hover over the (Shield and Star) and Click on the “Send ICE Contacts to Device” to activate the new Numbers
                                </li>
                                <li>
                                    Please note that the other Buttons are for Advanced Users and Future Functions only. Should this be Clicked your Device will not function correctly and a reconnection Fee will apply
                                </li>
                            </ul>
                        </>

                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleMessageClose}>Accept</Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>

    );
};

export default Profile