import React from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes, } from "react-router-dom";
import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
} from 'recoil';
import './App.css';
import ContextWrapper from "./Context/ContextWrapper";
import Admin from './Components/Admin';
import Tag from './Components/Tag';
import OtpEnter from './Components/OtpEnter';
import UserUpdate from './Components/UserUpdate';
import Login from './Components/Login';
import SignUp from './Components/SignUp';
import Error from './Components/Error';
import Demo from './Components/Demo';
import  DeviceManager from './Components/DeviceManager';
import Profile from "./Components/Profile";
import Profile2 from "./Components/Profile2";
import Users from "./Components/Users";
import RptUsers from './Components/RptUsers';
import ActivateProfile from "./Components/ActivateProfile";
import Forgot from "./Components/Forgot";
import Navbar from "./Components/Navbar";
import ActivateTag from "./Components/ActivateTag";
import { ProfileState } from "./Atoms/ProfileAtom";


const App = () => {


    const [profile, setProfile] = useRecoilState(ProfileState);




    return (
        <Router>
            {(typeof (profile._id) === "undefined") ? null : <Navbar />}

            <Routes>
                <Route exact path="/" element={<Navigate to="/login" />} />
                <Route path="/admin" element={<Admin />} />
                <Route path="/deviceManager" element={<DeviceManager />} />
                <Route path="/activateTag/:profileid/:tagid/:state" element={<ActivateTag />} />
                <Route path="/update_user/:userId/:otp" element={<UserUpdate />} />
                <Route path="/set_user/:userId/:time" element={<OtpEnter />} />
                <Route path="/:id" element={<Tag />} />
                <Route path="/login" element={<Login />} />
                <Route path="/sign-up" element={<SignUp />} />
                <Route path="/demo" element={<Demo />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/profile/:id" element={<Profile2 />} />
                <Route path="/users" element={<Users />} />
                <Route path="/rptusers" element={<RptUsers />} />
                <Route path="/activate/:id" element={<ActivateProfile />} />
                <Route path="/forgot/" element={<Forgot />} />
                <Route path="/error/:text/:credit" element={<Error />} />
                <Route path="*" element={<Error />} />
            </Routes >

        </Router >
    );
};

export default () => {

    return (
        <ContextWrapper>
            <App />
        </ContextWrapper>
    );
};


