import React, { useState, useEffect } from "react";
import {
    useRecoilState,
} from 'recoil';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from '@material-table/core';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Avatar from "@material-ui/core/Avatar";
import GlobalContext from "../Context/Context";
import Box from '@mui/material/Box';
//constants

import Loading from './Loading';
//icons
import Delete from '@material-ui/icons/Delete';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Refresh from '@material-ui/icons/Refresh';
import Save from '@material-ui/icons/Save';
import Activate from '@material-ui/icons/Wifi';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import Deactivate from '@material-ui/icons/WifiOff';
import RestoreIcon from "@material-ui/icons/PermIdentity";
import InfoIcon from '@mui/icons-material/Info';
import Copyright from './Copyright';
import { ProfileState } from "../Atoms/ProfileAtom";
import {
    deleteTag,
    postSetBillable,
    UpdateTag,
    GetTags,
    ResendTag,
    DoDeactivate,
    SendUpdate,
    DoActivate,
    DoDelete,
    corpUsers
} from "../API/Agent";




const useStyles = makeStyles({
    root: {
        width: "100%",
        height: "100%",
        backgroundColor: "blue",
        color: "green"
    },
    toolbar: {
        backgroundColor: "white"
    },
    caption: {
        color: "red",
        fontSize: "20px"
    },
    selectIcon: {
        color: "green"
    },
    select: {
        color: "green",
        fontSize: "20px"
    },
    actions: {
        color: "blue"
    }
});

const Admin = () => {
    const { title, setTitle } = React.useContext(GlobalContext);
    const rowsPerPage = 25;
    let navigate = useNavigate();
    const [profile, setProfile] = useRecoilState(ProfileState);
    const tableRef = React.createRef();
    const classes = useStyles();
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState(null);
    const [reasonValue, setReason] = useState("");
    const [open, setOpen] = React.useState(false);
    const [addReason, setAddReason] = React.useState(false);

    const handleNewReason = (user) => {
        setUser(user);
        setAddReason(true);
    }

    const handleCloseReason = () => {
        setAddReason(false);
    };

    const handleAddReasonAndClose = async () => {
        //alert(profile.role);
        user.reason = reasonValue;
        await   UpdateTag(user, profile.role).then((response) => {
            if (response !== '—') {
                alert('Info Updated: ' + user.names);
                getUsers();

            } else {
                //do something here placeholder
            }
        });
        setAddReason(false);
        setReason("");
    }

    useEffect(async () => {

        if (typeof (profile._id) === "undefined") navigate("/login");
        if (profile.role <= 2) navigate("/profile");
        getUsers();
    }, [])

    const getMonthDifference = (tag) => {
        const startDate = new Date(tag.creditDate)
        const endDate = new Date();
        const credit = parseInt(tag.creditCount);
        var diff = endDate.getMonth() -
            startDate.getMonth() +
            12 * (endDate.getFullYear() - startDate.getFullYear());

        return credit - diff;
    }


    const getUsers = async () => {
        if (profile.isCorperate) {
            setTitle(profile.corporate.corporateName + " - TAGS");
           corpUsers(profile._id).then((response) => {
                if (response !== '—') {

                    // response.sort(function (a, b) {
                    //     var x = a.member_number.toLowerCase();
                    //     var y = b.member_number.toLowerCase();
                    //     return x > y ? -1 : x < y ? 1 : 0;
                    // });
                    setUsers(response);
                } else {
                    //do something here placeholder
                }
            });
        } else {
            setTitle("RESQTAP ADMIN");
           await GetTags().then((response) => {
                if (response !== '—') {

                    // response.sort(function (a, b) {
                    //     var x = a.member_number.toLowerCase();
                    //     var y = b.member_number.toLowerCase();
                    //     return x > y ? -1 : x < y ? 1 : 0;
                    // });
                    setUsers(response);
                } else {
                    //do something here placeholder
                }
            });
        }

    }



    const setTextValue = (event) => {
        setReason(event.target.value);
    }

    const DeleteForever = async (user) => {

      await  deleteTag(user.member_number).then((response) => {
            if (response !== '—') {
                alert('deleted user tag for ' + user.names);
                getUsers();

            } else {
                //do something here placeholder
            }
        });
    }

    const setBillible = async (user) => {

        await   postSetBillable(user.member_number, !user.isBillable).then((response) => {
            if (response !== '—') {
                getUsers();

            } else {
                //do something here placeholder
            }
        });
    }

    const doResend = async (user) => {
        console.log(user);
        await   ResendTag(user.email, user._id, user.names, user.member_number).then((response) => {
            if (response !== '—') {
                alert('resent link for ' + user.names);
                getUsers();

            } else {
                //do something here placeholder
            }
        });

    }

    const doDeactivate = async (user, reason) => {
        //console.log(user);
        await    DoDeactivate(user.email, user._id, reason, user.names, user.member_number).then((response) => {
            if (response !== '—') {
                alert('deactivated user tag for ' + user.names);
                getUsers();

            } else {
                //do something here placeholder
            }
        });

    }

    const sendUpdate = async (user) => {

        await    SendUpdate(user.id, user.names, user.email, user.otp, user.member_number).then((response) => {
            if (response !== '—') {
                if (response.email === 'sent') {
                    alert('An email has been sent to the user with details to update their data.');
                } else {
                    alert('There was an error sending update email to the user');
                }
                //console.log('Success:', response);

            } else {
                //do something here placeholder
            }
        });
    }

    const doActivate = async (user) => {

        await    DoActivate(user.email, user._id, user.names, user.member_number).then((response) => {
            if (response !== '—') {
                alert('activated user tag for ' + user.names);
                getUsers();

            } else {
                //do something here placeholder
            }
        });

    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(), hour = d.getHours(), minute = d.getMinutes(), seconds = d.getSeconds();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;


        const theDate = [year, month, day].join('-') + " " + [hour, minute, seconds].join(':');
        return theDate;
    }

    function formatDate2(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(), hour = d.getHours(), minute = d.getMinutes(), seconds = d.getSeconds();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;


        const theDate = [year, month, day].join('-');
        return theDate;
    }

    const doDelete = async (user) => {

        await    DoDelete(user.email, user._id, user.names, user.member_number).then((response) => {
            if (response !== '—') {
                alert('deleted user tag for ' + user.names);
                getUsers();

            } else {
                //do something here placeholder
            }
        });

    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleUpdateAndClose = () => {
        console.log(reasonValue);
        doDeactivate(user, reasonValue);
        setOpen(false);
    };

    return (

        (users ?? []).length === 0 ?
            <Loading />
            :
            <>
                <CssBaseline />
                <div className={classes.root}>
                    <MaterialTable
                        title="RESQTAP ADMIN"
                        tableRef={tableRef}

                        columns={[
                            {
                                title: "Avatar",
                                field: "avatar",
                                width: "5%",
                                render: (rowData) => <><a href={`mailto:{rowdata.email}`}><Avatar src={rowData.photo} alt={rowData.name} />{rowData.email.toLowerCase()}</a> </>,
                                headerStyle: {
                                    backgroundColor: "#ff0000"
                                }
                            },
                            { title: "Member ID", field: "member_number", width: "5%" },
                            {
                                title: "Full Name",
                                field: "names",
                                width: "5%",

                                render: (rowData) => <><a target="_blank" style={{ color: '#f00e0e', text_decoration: 'none' }} href={`/${rowData._id}`} ><b>{rowData.names.toLocaleUpperCase()}</b></a></>,

                            },
                            { title: "Views", field: "tagsViewCount", width: "5%" },
                            {
                                title: "Last Viewed",
                                field: "visitAt",
                                width: "5%",
                                render: (rowData) => formatDate(rowData.visitAt)
                            },
                            {
                                title: "Credit left",
                                field: "creditCount",
                                width: "5%",
                                render: (rowData) => getMonthDifference(rowData)
                            },
                            {
                                title: "Credit Total",
                                field: "creditCount",
                                width: "5%"

                            }, {
                                title: "Credit Date",
                                field: "creditCount",
                                width: "5%",
                                render: (rowData) => formatDate2(rowData.creditDate)
                            }, {
                                title: "Info",
                                field: "reason",
                                width: "5%"
                            },


                        ]}
                        data={users}

                        actions={[//InfoIcon
                            {


                                icon: () => (
                                    <DeleteForeverIcon fontSize="large"
                                        style={{ fill: '#f00e0e', width: '32px' }}
                                    />
                                ),
                                tooltip: 'Delete Tag Forever',
                                onClick: (event, rowData) => {
                                    console.log(rowData);
                                    DeleteForever(rowData);
                                }
                            },
                            rowData => ({
                                width: 8,
                                icon: () => (
                                    <RestoreIcon fontSize="large"
                                        style={{ fill: '#00626F', width: '32px' }}
                                    />
                                ),
                                tooltip: 'Update Tag Directly',
                                onClick: (event, rowData) => {

                                    navigate("/update_user/" + rowData._id + "/" + rowData.otp);
                                },
                                disabled: rowData.acitive
                            }),
                            // {
                            //     width: 4,
                            //     icon: Refresh,
                            //     tooltip: 'Resend Link To Profile',
                            //     onClick: (event, rowData) => {
                            //         console.log(rowData);
                            //         doResend(rowData);
                            //     }
                            // },
                            {
                                width: 4,
                                icon: Save,
                                tooltip: 'Send Update To Profile',
                                onClick: (event, rowData) => {
                                    sendUpdate(rowData);
                                }
                            },
                            {
                                width: 8,
                                icon: Delete,
                                tooltip: 'Send Delete To Profile',
                                onClick: (event, rowData) => {
                                    //alert("You saved " + rowData.names)
                                    doDelete(rowData);
                                }
                            }
                            , rowData => ({
                                width: 8,
                                icon: rowData.active ? () => <Activate fontSize="large"
                                    style={{ fill: '#39FF14', width: '32px' }} /> : () => <Deactivate fontSize="large"
                                        style={{ fill: '#f00e0e', width: '32px' }} />,
                                tooltip: rowData.active ? 'Send Deactivate To Profile' : 'Send Activate To Profile',
                                onClick: (event, rowData) => {
                                    if (rowData.active) {
                                        handleClickOpen();
                                        setUser(rowData)
                                    } else {
                                        doActivate(rowData);
                                    }

                                }
                            }),
                            rowData => ({
                                width: 8,
                                icon: rowData.isBillable ? () => <AttachMoneyIcon fontSize="large"
                                    style={{ fill: '#5a8a3d', width: '32px' }} /> : () => <MoneyOffIcon fontSize="large"
                                        style={{ fill: '#c1ddb1', width: '32px' }} />,
                                tooltip: rowData.isBillable ? 'Tag is billable' : 'Tag is not billable',
                                onClick: (event, rowData) => {
                                    //alert("You saved " + rowData.names)
                                    setBillible(rowData);
                                }
                            }),

                            {
                                width: 8,
                                icon: () => (
                                    <InfoIcon fontSize="large"
                                        style={{ fill: '#0047BB', width: '32px' }}
                                    />
                                ),
                                tooltip: 'For Office Use Only',
                                onClick: (event, rowData) => {
                                    //alert("You saved " + rowData.names)
                                    handleNewReason(rowData);
                                }
                            }

                        ]}
                        options={{
                            pageSize: rowsPerPage,
                            pageSizeOptions: [25, 50, 100],
                            actionsColumnIndex: -1,
                            search: true,
                            icon: "RestoreIcon",
                            tooltip: "Refresh Data",
                            headerStyle: {
                                backgroundColor: "#ff0000",
                                color: "#FFFFFF",
                            },
                            sorting: true,
                            onClick: () => tableRef.current && tableRef.current.onQueryChange()
                        }}

                    />
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle>Deactivate</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Please fill in the reason why this Tag is being deactivated?
                            </DialogContentText>
                            <TextField
                                autoFocus
                                onChange={setTextValue}
                                margin="dense"
                                id="name"
                                label="Reason why deactivate?"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button onClick={handleUpdateAndClose}>Update</Button>
                            {/* sendUpdate(rowData); */}
                        </DialogActions>
                    </Dialog>

                    <Dialog open={addReason} onClose={handleCloseReason} sx={{ textAlign: "center" }}>
                        <DialogTitle>Add Office Information</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Provided for office use.
                            </DialogContentText>
                            <TextField
                                onChange={e => setReason(e.target.value)}
                                margin="dense"
                                id="reason"
                                label="Info"
                                type="text"
                                fullWidth
                                value={reasonValue}
                                variant="standard"
                            />

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseReason}>Cancel</Button>
                            <Button onClick={handleAddReasonAndClose}>Add</Button>
                            {/* sendUpdate(rowData); */}
                        </DialogActions>
                    </Dialog>

                </div>
                <Copyright sx={{ mt: 5 }} />&nbsp;
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    component="div"
                >ICE-TAGS System v2.5</Box>
            </>
    )
}

export default Admin;




















