import React, { useState } from 'react';
import { useParams } from "react-router-dom";

import logo from '../logo.png';

const OtpEnter = () => {
    const [otp, setOtp] = useState(null);

    let { userId, time } = useParams();

    let t = Date.now();
    let diff = t.valueOf() - time.valueOf();

    let hours = diff / 1000 / 60 / 60; // Convert milliseconds to hours

    hours = JSON.stringify(hours).split('.');

    return (
        Number(hours[0]) < 12
            ? (
                <div className="container">
                    <img className="logo" src={ logo } alt="logo" />
                    <h3>Be Prepared, Save a Life!™️</h3>

                    <div className="inputDiv">
                        <input type="hidden" name="userId" value={ userId } />
                        <label> PIN</label>
                        <input type="text" name="otp" placeholder="ENTER PIN" onKeyUp={ (e) => setOtp(e.target.value) } />
                    </div>

                    <div className="inputDiv">
                        <button onClick={ () => window.open(`/update_user/${ userId }/${ otp }`) } name="send">
                            Submit
                        </button>
                    </div>
                    <div className="foot">
                        <p> View other ICE-TAGS products</p>
                        <a href="https://www.ice-tags.co.za"> www.ice-tags.co.za</a>
                    </div>
                </div>
            )
            : (
                <div className="container">
                    <h2>Link Expired</h2>
                </div>
            )
    )
};

export default OtpEnter;
