import React, { useState, useEffect } from "react";
import {

    useRecoilState,

} from 'recoil';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Loading from './Loading';


import { ProfileState } from "../Atoms/ProfileAtom";
import {
    register, profiles, activate, update, deleteUser, reactivate, deleteAuth,
    alighnprofile, clearprofiletoken, alignUsersAccounts, corpProfiles
} from "../API/Agent";
import Copyright from './Copyright';
import { InputLabel } from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';
import GlobalContext from "../Context/Context";
const useStyles = makeStyles({
    root: {
        width: "100%",
        height: "100%"
    }
});


const RptUsers = () => {
    const { title, setTitle } = React.useContext(GlobalContext);
    const rowsPerPage = 25;
    let navigate = useNavigate();
    const [profile, setProfile] = useRecoilState(ProfileState);

    const classes = useStyles();

    const [users, setUsers] = useState([]);
    const [state, setState] = React.useState(false);
    const getMuiTheme = () => createTheme({
        components: {
            MUIDataTableBodyCell: {
                styleOverrides: {
                    root: {
                        backgroundColor: "#fff",
                        color: "#000",
                        borderBottom: "none"
                    }
                }
            },
            // MUIDataTableHead: {
            //     styleOverrides: {
            //         root: {
            //             backgroundColor: "#1D252D",
            //             color: "rgb(255, 255, 255)",
            //             borderBottom: "none"
            //         }
            //     }
            // },
            // MUIDataTableToolbar: {
            //     styleOverrides: {
            //         root: {
            //             backgroundColor: "#1D252D",
            //             color: "rgb(255, 255, 255)"
            //         }
            //     }
            // },
            // MUIDataTablePagination: {
            //     styleOverrides: {
            //         root: {
            //             backgroundColor: "#1D252D",
            //             color: "rgb(255, 255, 255)"
            //         }
            //     }
            // },
            // MUIDataTableDivider: {
            //     styleOverrides: {
            //         root: {
            //             backgroundColor: "#1D252D",
            //             color: "rgb(255, 255, 255)"
            //         }
            //     }
            // },
            MUIDataTableHeadCell: {
                styleOverrides: {
                    root: {
                        backgroundColor: "#ff0000",
                        color: "#fff",
                        //position:'fixed'
                    },
                    toolButton: {
                        width: "fit-content",
                        fblock_size: "fit-content",
                      }
                }
            },

        }
    })
    useEffect(async () => {

        if (typeof (profile._id) === "undefined") navigate("/login");
        if (profile.role <= 2) navigate("/profile");
        if (profile.isCorperate) {
            setTitle(profile.corporate.corporateName + " - PROFILES");
            corpProfiles(profile._id).then((response) => {

                if (response !== "undefined") {
                    response.sort(function (a, b) {
                        var x = new Date(a.createdAt);
                        var y = new Date(b.createdAt);
                        return x ? -1 : y ? 1 : 0;
                    });
                    setUsers(response);

                } else {
                    navigate("/login");
                }
            });
        } else {
            setTitle("RESQTAP PROFILES");
            profiles().then((response) => {

                if (response !== "undefined") {
                    response.sort(function (a, b) {
                        var x = new Date(a.createdAt);
                        var y = new Date(b.createdAt);
                        return x ? -1 : y ? 1 : 0;
                    });
                    setUsers(response);

                } else {
                    navigate("/login");
                }
            });
        }

    }, [])

    const options = {
        filter: true,
        selectableRows: false,
        usePaperPlaceholder: false,


    };
    const columns = [
        {
            name: "firstname",
            label: "Name",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "lastname",
            label: "Surname",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "email",
            label: "Email",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "cellNumber",
            label: "Cell Number",
            options: {
                filter: true,
                sort: true,
            }
        },];
    return (
        (users ?? []).length === 0 ?
            <Loading />
            :
            <>
                <CssBaseline />
                <div className={classes.root}>
                    <ThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable
                            title={"Profile List"}
                            data={users}
                            columns={columns}
                            options={options}
                        />
                    </ThemeProvider>
                </div>
                <Copyright sx={{ mt: 5 }} />
            </>
    )
}

export default RptUsers;

