import React, { useState, useEffect } from 'react';
import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
} from 'recoil';
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from '../logo.png'
import { green, red } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';
import { ProfileState } from "../Atoms/ProfileAtom";
import {TokenState} from "../Atoms/TokenAtom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { get_profile, signin } from "../API/Agent";
import Copyright from './Copyright';

const theme = createTheme();

const Login = () => {
    // const tagsStore = useTagsStore();
    let navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [profile, setProfile] = useRecoilState(ProfileState);

    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);


    useEffect(()=>{
        const token = localStorage.getItem('token');
            if(token){

                setPassword("");
                setUserName("");
                setSuccess(true);
                setLoading(false);
             
                get_profile().then((resp)=>{
                    setProfile({ ...resp });
                    console.log("profile ",resp);
                    navigate("/profile");
              });
            }

    },[]);

    const buttonSx = {

        ...(success && {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        }), mt: 3, mb: 2,
        ...(!success && {
            bgcolor: red[500],
            '&:hover': {
                bgcolor: red[700],
            },
        })
    };
    const setEmailValue = (event) => {
        setUserName(event.target.value);
    }
    const setPasswordValue = (event) => {
        setPassword(event.target.value);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!loading) {
            setSuccess(false);
            setLoading(true);
            console.log(loading);

            try {
                // loginTags(username, password).then((response) => {

                //     if (response !== undefined) {
                //         setProfile({ ...response });
                //         setPassword("");
                //         setUserName("");
                //         setSuccess(true);
                //         setLoading(false);

                //         navigate("/profile");
                //     } else {
                //         handleClickOpen();
                //     }
                // });

                signin(username, password).then((response) => {

                    if (response !== undefined) {
                        localStorage.setItem('token', response.token);

                        setPassword("");
                        setUserName("");
                        setSuccess(true);
                        setLoading(false);
                     
                        get_profile().then((resp)=>{
                            setProfile({ ...resp });
                            navigate("/profile");
                      });
                        // 
                        

                        // 
                    } else {
                        handleClickOpen();
                    }
                });


            } catch (error) {
                console.log({ ...error })
                setSuccess(false);
                setLoading(false);
            }
        }
    };

    const handleClickOpen = () => {
        setOpen(true);

    };

    const handleClose = () => {
        setOpen(false);
        setSuccess(false);
        setLoading(false);
        setPassword("");
        setUserName("");
    };
    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <img
                        sx={{ m: 1, bgcolor: 'secondary.main' }}
                        alt="ICE-TAGS"
                        src={logo}
                    />
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            onChange={setEmailValue}
                            value={username}
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            onChange={setPasswordValue}
                            value={password}
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        />
                        <Box sx={{ m: 1, position: 'relative' }}>
                            <Button
                                type="submit"
                                sx={buttonSx}
                                disabled={loading}
                                fullWidth
                                variant="contained"

                            >
                                Sign In
                            </Button>
                            {loading && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        color: green[500],
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            )}

                        </Box>
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {"ICE-TAGS System Error"}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    The Email address or your password you have entered is incorrect. Please note that both Email or Password are case sensitive. Please enter them again carefully. Thank you.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                {/* <Button onClick={handleClose}>Disagree</Button> */}
                                <Button onClick={handleClose} autoFocus>
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Grid container>
                            <Grid item xs>
                                <Link href="/forgot" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="/sign-up" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    )
}

export default Login