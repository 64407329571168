import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import {
  makeStyles,
  //   Card,
  //   CardContent,
  //   CardMedia,
  //   Avatar,
  //   Typography,
} from "@material-ui/core";
// import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import PhoneIcon from "@material-ui/icons/Phone";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PersonIcon from "@material-ui/icons/PermIdentity";
// import CardActions from '@mui/material/CardActions';
// import CardHeader from '@mui/material/CardHeader';
import { getUserByTag } from "../API/Agent";
//import IconButton from '@mui/material/IconButton';
import RestoreIcon from "@material-ui/icons/PermIdentity";

import Save from '@material-ui/icons/Save';
import CloseIcon from "@material-ui/icons/Close";
// import Grid from "@mui/material/Grid";


//import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/material/styles';
import classnames from 'classnames';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import red from '@mui/material/colors/red';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 15,
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.card,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  cardTop: {
    backgroundColor: "#1976D2",
    color: '#ffffff',
  },
  actions: {
    display: 'flex',
    margin: theme.spacing(-1, 0, 0, 0),
    backgroundColor: "#f44336",
    foregroundcolor: "#FFF000"
  },
  large: {
    margin: theme.spacing(-1, 2, 0),
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginRight: -8,
    },
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    verticalAlign: "middle",
    marginRight: theme.spacing(0.5),
    backgroundColor: '#ff0000',
    color: '#ffffff'
  },
}));


const ProfileTag = ({ tagItem, deleteItemCallback }) => {

  const limit = (element, max_chars) => {


    if (element.length > max_chars) {
      if (max_chars > 5)
        return element.substr(0, max_chars) + "...";
      else
        return element.substr(0, max_chars)
    } else {
      return element;
    }
  }

  let navigate = useNavigate();
  const classes = useStyles();

  const [tag, setTag] = useState({});
  useEffect(async () => {
    getUserByTag(tagItem.member_number).then((resp) => {
  
      setTag(resp);
    })
  }, [tagItem.member_number])
  return ( tag === null?null:
    <Card className={classes.card}>
      <CardHeader
        className={classes.cardTop}
        avatar={
          <Avatar aria-label="Recipe" className={classes.avatar}>
            {limit(`${tag.names}`, 1)}
          </Avatar>
        }
        action={
          <Tooltip title="Delete Tag">
            <IconButton
             style={{ width: '32px'}}
           
              aria-label="add"
              sx={{ ml: 3.5, display: "flex", width: '32px', justifyContent: "flex-end" }}
              className="whiteCss" onClick={() => deleteItemCallback(tag)}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        }
        title={tag.member_number}
        subheader={tag.id_number}
      />
      <CardMedia align="center">
        <Avatar
          alt={tag.names}
          src={tag.photo}
          className={classes.large}
          sx={{ width: '150px', height: '150px' }}
        />
      </CardMedia>
      <CardContent>

          <Typography
            className={classes.text}
            color="textSecondary"
            variant="h6"
            align="left"
          >
            {limit(`${tag.names}`, 20)}
          </Typography>
          <Typography component="p">
            <AlternateEmailIcon className={classes.avatar} fontSize="small" />
            {limit(`${tag.email}`, 20)}
          </Typography>
          <Typography component="p">
            <PersonIcon className={classes.avatar} fontSize="small" />

            ICE-1 {limit(`${tag.ice1_name}`, 20)}
          </Typography>
          <Typography component="p">
            <PhoneIcon className={classes.avatar} fontSize="small" />
            ICE-1   {limit(`${tag.ice1_no}`, 20)}
          </Typography>
          <Typography component="p">
            <LocationOnIcon className={classes.avatar} fontSize="small" />
            {limit(`${tag.country}`, 20)}
          </Typography>
        </CardContent>
        <CardActions className={classes.actions} >
        <Tooltip title="Update Tag">
            <IconButton aria-label="add to favorites"
             
              style={{ width: '32px'}}
              sx={{ ml: 12, display: "flex", width: '32px', justifyContent: "flex-end" }}
              onClick={() => {
                navigate("/update_user/" + tag._id + "/" + tag.otp);
              }} >
              <Save className="whiteCss" />
            </IconButton>
          </Tooltip>
          <Tooltip title="View Tag">
            <IconButton
             
              style={{ width: '32px'}}
              sx={{ display: "flex", width: '32px', justifyContent: "flex-end" }}
              aria-label="share" onClick={() => {
                navigate("/" + tag._id);
              }} >
              <RestoreIcon className="whiteCss" />
            </IconButton>
          </Tooltip>
        
     
        </CardActions>
    </Card>
  );}

export default ProfileTag