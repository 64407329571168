import React from 'react'
import web404 from '../Images/web404.gif';
import buycredit from '../Images/buycredit.png';
import { useParams } from "react-router-dom";
const Error = () => {
    let { text, credit } = useParams();
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>

            {!credit
                ? <img style={{ minWidth: '100%', height: 'auto' }} src={web404} />
                : <img style={{ minWidth: '100%', height: 'auto' }} src={buycredit} />}
        </div>
    )
}
export default Error