import * as React from "react";
import {
    useRecoilState,
} from 'recoil';
import {
    createTheme,
    AppBar,
    Toolbar,
    CssBaseline,
    Typography,
    makeStyles,
    Avatar
} from "@material-ui/core";
import GlobalContext from "../Context/Context";
import { ProfileState } from "../Atoms/ProfileAtom";
import { Link } from "react-router-dom";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import AodIcon from '@mui/icons-material/Aod';
import GroupsIcon from '@mui/icons-material/Groups';
import logo from '../logo.png'
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Logout from '@mui/icons-material/Logout';
import { ProfileStateChange, RefreshStateChange } from "../Atoms/ProfileAtom";


const theme = createTheme({
    palette: {
        primary: {
            light: '#757ce8',
            main: '#1976D2',
            dark: '#FFD700',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#fff',
        },
    },
});


const useStyles = makeStyles((theme) => ({
    navlinks: {
        marginLeft: theme.spacing(10),
        display: "flex",
    },
    logo: {
        flexGrow: "1",
        cursor: "pointer",

    },
    link: {
        textDecoration: "none",
        color: "white",
        fontSize: "20px",

        marginLeft: theme.spacing(20),
        "&:hover": {
            color: "red",
            borderBottom: "1px solid white",
        },
    },
}));



const Navbar = () => {
    const { title, setTitle} = React.useContext(GlobalContext);
   // const { updateTitle, state: { title }, } = React.useContext(globalContext);
    const [counter, setCounter] = useRecoilState(RefreshStateChange);
    const [profile, setProfile] = useRecoilState(ProfileState);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {

        setAnchorEl(event.currentTarget);
    };
    const handleClose = (e, index) => {
        setAnchorEl(null);
    };
    return (
        <AppBar position="sticky" elevation={3}  >
            <CssBaseline />
            <Toolbar sx={{ padding: '0px,0px,0px,0px', width: "100%" }}>
                <Box component='div' sx={{ display: 'flex', justifyContent: 'space-between', width: "100%" }}>
                    {/* Logo */}
                    <Box component={Link} to="/Profile" >
                        <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} >
                            <Avatar
                                sx={{ m: 1, bgcolor: 'secondary.main' }}
                                alt="ICE-TAGS"
                                src={logo}
                            ></Avatar>
                        </IconButton>
                    </Box>
                    {/* Links */}
                    <Box>
                        <Typography variant="h6" color="inherit" component="div">
                            {title}
                        </Typography>
                    </Box>
                    {/* B Link */}

                    <Box sx={{ display: "flex", }}>
                        <Typography variant="h6" color="inherit" component="div">
                            Welcome back, {profile.firstname}
                        </Typography>
                        <Typography variant="h6" color="inherit" component="div">
                            <Tooltip title="ICE-TAGS MENU">
                                <IconButton
                                    onClick={handleClick}
                                    size="small"
                                    sx={{ ml: -1, mt: -1 }}
                                    aria-controls={open ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    color="inherit"
                                >
                                    <AccountCircle />
                                </IconButton>
                            </Tooltip>
                            <Menu

                                anchorEl={anchorEl}
                                id="account-menu"
                                open={open}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={{
                                    elevation: 3,
                                    sx: {
                                        zIndex: 'modal',
                                        width: "210px",
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                            width: 48,
                                            height: 48,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        '&:before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <MenuItem sx={{ width: '100%' }} component={Link} to="/Profile">
                                    <ManageAccountsIcon /> My Profile
                                </MenuItem>
                                <Box clone display={{ sm: profile.role > 2 ? "" : "none" }}>
                                    <MenuItem sx={{ width: '100%' }} component={Link} to="/Users">
                                        <GroupsIcon /> All Profiles
                                    </MenuItem>
                                </Box>

                                <Box clone display={{ sm: profile.role > 2 ? "" : "none" }}>
                                    <MenuItem sx={{ width: '100%' }} component={Link} to="/Admin">
                                        <HowToVoteIcon /> Resqtap Admin
                                    </MenuItem>
                                </Box>

                                <Box clone display={{ sm: profile.role > 2 ? "" : "none" }}>
                                    <MenuItem sx={{ width: '100%' }} component={Link} to="/deviceManager">
                                        <AodIcon /> Device Manager
                                    </MenuItem>
                                </Box>
                                <Box clone display={{ sm: profile.role > 2 ? "" : "none" }}>
                                    <MenuItem sx={{ width: '100%' }} component={Link} to="/rptusers">
                                        <AccountCircleIcon /> Report Users
                                    </MenuItem>
                                </Box>
                                <Divider />
                                <MenuItem sx={{ width: '100%' }} onClick={() => {
                                    window.open("https://ice-tags.co.za/", '_blank');
                                }}>
                                    <ListItemIcon>
                                        <PersonAdd fontSize="small" />
                                    </ListItemIcon>
                                    Buy another Tag
                                </MenuItem>
                                {/* <MenuItem sx={{ width: '100%' }}>
                                    <ListItemIcon>
                                        <Settings fontSize="small" />
                                    </ListItemIcon>
                                    Settings
                                </MenuItem> */}
                                <MenuItem sx={{ width: '100%' }} component={Link} to="/" onClick={() => {
                                    localStorage.clear();
                                    setProfile([]);
                                    setCounter(1);
                                }}>
                                    <ListItemIcon>
                                        <Logout fontSize="small" />
                                    </ListItemIcon>
                                    Logout
                                </MenuItem>
                            </Menu>
                        </Typography>
                    </Box>
                </Box>
            </Toolbar>
        </AppBar >
    );
}

export default Navbar;