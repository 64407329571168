import axios from "axios";
import { BACKEND_URL } from '../Constants';



const instance = axios.create({

  baseURL: BACKEND_URL,
  timeout: 5000
});


instance.interceptors.request.use(

  async (config) => {
    const token = localStorage.getItem('token');

    console.log("auth", token);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default instance;
