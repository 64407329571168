import axios from "axios";
import resqtapApi from '../API/resqtap'


const getsigniture = async () => await resqtapApi
    .get('/signiture/')
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });



export const signin = async (email, password) => await resqtapApi.post('/signin', { email, password })
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });


export const get_profile = async () => await resqtapApi.get('/profile')
    .then(response => response.data[0])
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });

export const uploudPicture = async (image, name) => {
    //const timestamp = Date.now() / 1000;
    const cloudName = 'resqtap';
    const url = `https://api.cloudinary.com/v1_1/${cloudName}/auto/upload`;
    const uploadPreset = 'ml_default';
    const apiKey = '412946615964527';
    //const apisecret = 'h7LWW4UfMmZM5QUqN6SRvYufj5A';




    const { signature, timestamp } = await getsigniture();

    // const form = new FormData()
    // form.append('file', image)
    // const res = await fetch(
    //   `https://api.cloudinary.com/v1_1/${cloudName}/image/upload?api_key=${apiKey}&upload_preset=${uploadPreset}&timestamp=${timestamp}&signature=${signature}&public_id=${name}`,
    //   {
    //     method: 'POST',
    //     body: form,
    //   }
    // )
    // const data = await res.json()
    // return data.secure_url

    let formData = new FormData();
    formData.append("api_key", apiKey);
    formData.append("file", image);
    //formData.append("public_id", name);
    formData.append("timestamp", timestamp);
    //formData.append("upload_preset", uploadPreset);
    formData.append("signature", signature);
    //formData.append("folder", "signed_upload_demo_form");
    const response = await axios
        .post(url, formData)

        .catch(error => {
            // console.log(error);
            if (error.response && error.response.status === 404) {
                return `\u2014`;
            }
        });

    return response.data.secure_url;
};





export const getUser = async (id) => await resqtapApi.post("/getSingle/" , {id})
.then(response => response.data)
.catch(error => {
    if (error.response && error.response.status === 404) {
        return `\u2014`;
    }
});

export const getUpdateUser = async (userId, otp) => await resqtapApi.post("/update_user",  {userId , otp} )
.then(response => response.data)
.catch(error => {
    if (error.response && error.response.status === 404) {
        return `\u2014`;
    }
});

export const geo = (numbers, names) => {
    let status = "";
    let lat = "";
    let lng = "";

    if (!navigator.geolocation) {
        let postData =
        {
            "Lat": null,
            "Long": null,
            "Numbers": numbers,
            "Name": names
        };
        resqtapApi.post('/functions/sms', postData, {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            'Content-Type': 'text/json'
        })
            .then(response => response.data)
            .catch(error => {
                if (error.response && error.response.status === 404) {
                    return `\u2014`;
                }
            });
    } else {

        navigator.geolocation.getCurrentPosition((position) => {
            status = "";
            lat = position.coords.latitude;
            lng = position.coords.longitude;
            let postData =
            {
                "Lat": lat,
                "Long": lng,
                "Numbers": numbers,
                "Name": names
            };
            resqtapApi.post('/functions/sms', postData, {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                'Content-Type': 'text/json'
            })
                .then(response => response.data)
                .catch(error => {
                    if (error.response && error.response.status === 404) {
                        return `\u2014`;
                    }
                });
        }, () => {
            status = 'Unable to retrieve your location';
        });

    }
}




export const loginTags = async (username, password) => await resqtapApi.get('/profiles/' + username + '/' + password, {
    headers: {
        'Content-Type': 'text/json'
    }
})
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });

export const register = async (profile) => await resqtapApi.post('/profile', profile)
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });

    export const sendInstructionMessage = async (message) => await resqtapApi.post('/sendInstructionMessage/', message)
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });

    //device management
    export const addIceContactsToDevice = async (message) => await resqtapApi.post('/addIceContactsToDevice/', message)
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });
    //
    export const addIceNumbersToDevice = async (message) => await resqtapApi.post('/addIceNumbersToDevice/', message)
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });

    export const restartDevice = async (message) => await resqtapApi.post('/restartDevice/', message)
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });

    export const refreshDevice = async (message) => await resqtapApi.post('/refreshDevice/', message)
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });

    export const restoreFactoryDevice = async (message) => await resqtapApi.post('/restoreFactoryDevice/', message)
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });

    export const deviceOff = async (message) => await resqtapApi.post('/deviceOff/', message)
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });
    //deviceOff

    export const deviceResetPassword = async (message) => await resqtapApi.post('/deviceResetPassword/', message)
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });
    //deviceResetPassword

    export const setIpPortToDevice = async (message) => await resqtapApi.post('/setIpPortToDevice/', message)
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });
  //setIpPortToDevice

    export const smsList = async (id_number) => await resqtapApi.get('/smsList/' )
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });

    export const updateJob = async (jobId, data) => await resqtapApi.put('/patchIntegrationJobs/' + jobId, data)
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });
  


export const update = async (profile, id) => await resqtapApi.patch('/profile/' + id, profile)
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });

export const validateTag = async (tagId, idNumber, profileId) => await resqtapApi.get('/tag_validate/' + tagId + '/' + idNumber + '/' + profileId)
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });

export const reactivate = async (profile) => await resqtapApi.post('/reminder', profile)
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });

export const allIntegrationJobs = async () => await resqtapApi.get('/getAllIntegrationJobs/')
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });

    export const activateProfileTag = async (id, tagid) => await resqtapApi.get('/profile/activate/' + id + '/' + tagid)
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });


    export const getDeviceIce = async (deviceId) => await resqtapApi.get('/getDeviceIce/' + deviceId )
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });

export const activate = async (id) => await resqtapApi.get('/profileActivateWeb/' + id)
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });

export const getTagsById_Number = async (id_number) => await resqtapApi.get('/tags_by_email/' + id_number)
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });
//:id
export const forgot = async (id) => await resqtapApi.get('/forgot/' + id)
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });

export const deleteUser = async (id) => await resqtapApi.delete('/profile/' + id)
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });

export const deleteAuth = async (email) => await resqtapApi.delete('/auth/delete/' + email)
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });

export const profiles = async () => await resqtapApi.get('/profiles/')
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });

    export const corpProfiles = async (id) => await resqtapApi.post('/profilesByParent', { id })
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });

    export const corpUsers=  (id) =>  resqtapApi.post('/adminProfilesUsers', { id })
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });
    
    //profilesUsers
    //getProfilesDevices
    export const getProfilesDevices = async () => await resqtapApi.get('/profilesDevices/')
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });
export const alighnprofile = async (email) => await resqtapApi.post('/profilealign', { email })
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });

    export const clearprofiletoken = async (email) => await resqtapApi.post('/cleartoken', { email })
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });

    export const alignUsersAccounts = async (email) => await resqtapApi.get('/profileAlighnment/' + email)
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });

//tags
export const deleteTag = async (id) => await resqtapApi.delete('/GetUsers/' + id)
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });

export const getProfile = async (id) => await resqtapApi.get('/profile/' + id)
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });

    export const getProfileByEmail = async (email) => await resqtapApi.get('/profilebyemail/' + email)
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });

//Tag section

export const UpdateTag = async (tag, roleid) => await resqtapApi.post('/data_updated/' + roleid, tag)
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });

export const ResendTag = async (email, id, names, member_number) => await resqtapApi.post('/resendTag', { email, id, names, member_number })
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });

export const DoDeactivate = async (email, id, reason, names, member_number) => await resqtapApi.post('/deactivate', { email, id, reason, names, member_number })
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });

export const DoActivate = async (email, id, names, member_number) => await resqtapApi.post('/activate', { email, id, names, member_number })
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });

export const DoDelete = async (email, id, names, member_number) => await resqtapApi.post('/delete', { email, id, names, member_number })
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });

export const SendUpdate = async (id, names, email, otp, member_number) => await resqtapApi.post('/update_req', { id, names, email, otp, member_number })
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });



export const GetTags = async () => await resqtapApi.get('/GetUsers/')
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });





export const getUserByTag = async (id) => await resqtapApi.get("/tag/" + id, {
    headers: {
        'Content-Type': 'text/json'
    }
})
    .then(response => response.data)
    .catch(error => {
        if (error.response && error.response.status === 404) {
            return `\u2014`;
        }
    });

//
export const postSetBillable = async (id, state) => await resqtapApi.post('/tagSetBillable', { id, state })
.then(response => response.data)
.catch(error => {
    if (error.response && error.response.status === 404) {
        return `\u2014`;
    }
});


    export const getProfileBillable = async (id, state) => await resqtapApi.get("/updateBillingProfile/" + id + "/" + state, {
        headers: {
            'Content-Type': 'text/json'
        }
    })
        .then(response => response.data)
        .catch(error => {
            if (error.response && error.response.status === 404) {
                return `\u2014`;
            }
        });
    //updateBillingProfile