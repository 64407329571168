import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@mui/material/CssBaseline';
import {
    RecoilRoot,
} from 'recoil';
import App from './App';


import './index.css';

ReactDOM.render(

    <RecoilRoot>
        <CssBaseline />
        <App />
    </RecoilRoot>

    , document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))

