import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import logo from '../logo.png'
import safty from '../Images/safety.jpg';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from "@mui/material/Paper";
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import yellow from "@material-ui/core/colors/yellow";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { activateProfileTag } from "../API/Agent";
import { cloneDeep } from 'lodash';


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://www.ice-tags.co.za">
                ICE-TAGS Products
            </Link>{' '}
            2013
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

const ActivateTag =()=>{
  const [open, setOpen] = React.useState();
  let { profileid,tagid,state } = useParams();

    useEffect(()=>{
        if(state === 'true')
        activateProfileTag(profileid,tagid);

    },[]);

  return (
    <React.Fragment>

    <ThemeProvider theme={theme}>

        <Paper elevation={12} style={{
            margin: "auto",
            width: '60%',

            border: `5px solid ${yellow[100]}`,
            backgroundColor: yellow[50],
            borderRadius: 25,
        }} >
            <Box p={1}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <img alt="Be Prepared, Save a Life" src={logo} />
                        <Typography component="h1" variant="h5">
                            Be Prepared, Save a Life!™️
                        </Typography>

                        <Box component="form" noValidate sx={{ mt: 3 }}>

                            <Card sx={{ maxWidth: 345 }}>
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image={safty}
                                    alt="Be Prepared, Save a Life!™️"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Activation is complete
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                    `{state==='true' 
                                    ? 'ICE TAG NUMBER: ' + tagid + ' was  Activated'
                                    :'ICE TAG NUMBER: ' + tagid + ' was not Activated' }
                                    `
                                       
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Link sx={{ margin: "auto" }} color="#ff0000" href="/login">
                                        RESQTAP LOGIN
                                    </Link>

                                </CardActions>
                            </Card>


                        </Box>
                    </Box>
                    <Copyright sx={{ mt: 5 }} />
                </Container>
            </Box>
        </Paper>
    </ThemeProvider>
</React.Fragment>
  );
}

export default ActivateTag;