import React from "react";
import { useParams } from "react-router-dom";
import logo from '../logo.png'
import safty from '../Images/safety.jpg';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from "@mui/material/Paper";
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import yellow from "@material-ui/core/colors/yellow";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { update, activate, getTagsById_Number } from "../API/Agent";
import { cloneDeep } from 'lodash';






function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://www.ice-tags.co.za">
                ICE-TAGS Products
            </Link>{' '}
            2013
            {'.'}
        </Typography>
    );
}

const theme = createTheme();





const ActivateProfile = () => {
    let { id } = useParams();
    activate(id).then((resp) => {
        if (resp) {
            if (resp !== null) {
                let profileClone = cloneDeep(resp)



                getTagsById_Number(resp.id_number).then((resp) => {
                    if (resp.length > 0) {
                        try {
                            resp.forEach((tag, i) => {
                                console.log(tag.member_number);
                                let member = new Object();
                                member.member_number = tag.member_number;
                                const members = resp.find(
                                    element => {
                                        if (element.member_number === tag.member_number) {
                                            return true;
                                        }
                                    });
                                    if(members === undefined) {
                                        profileClone.member_numbers.push(member);
                                    }
                                
                            });

                            update(profileClone, profileClone._id).then((response) => {

                                if (response !== '—') {

                                } else {
                                    //do something here placeholder
                                }
                            });
                        } catch (error) {

                            console.log({ ...error })

                        }
                    }


                })
            }
        }
    });


    return (

        <React.Fragment>

            <ThemeProvider theme={theme}>

                <Paper elevation={12} style={{
                    margin: "auto",
                    width: '60%',

                    border: `5px solid ${yellow[100]}`,
                    backgroundColor: yellow[50],
                    borderRadius: 25,
                }} >
                    <Box p={1}>
                        <Container component="main" maxWidth="xs">
                            <CssBaseline />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <img alt="Be Prepared, Save a Life" src={logo} />
                                <Typography component="h1" variant="h5">
                                    Be Prepared, Save a Life!™️
                                </Typography>

                                <Box component="form" noValidate sx={{ mt: 3 }}>

                                    <Card sx={{ maxWidth: 345 }}>
                                        <CardMedia
                                            component="img"
                                            height="140"
                                            image={safty}
                                            alt="Be Prepared, Save a Life!™️"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                                Activation is complete
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                You have successfully validated your email address you may now proceed to
                                                login. Click on the button below.
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                            <Link sx={{ margin: "auto" }} color="#ff0000" href="/login">
                                                RESQTAP LOGIN
                                            </Link>

                                        </CardActions>
                                    </Card>


                                </Box>
                            </Box>
                            <Copyright sx={{ mt: 5 }} />
                        </Container>
                    </Box>
                </Paper>
            </ThemeProvider>
        </React.Fragment>

    );
};

export default ActivateProfile;
