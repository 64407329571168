import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import {

    useRecoilState,

} from 'recoil';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';

//import {Image, Video, Transformation} from 'cloudinary-react';
//constants

import Loading from './Loading';
import logo from '../logo.png'
import pets from '../pets.png'
import GlobalContext from "../Context/Context";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
//MUI

import Avatar from '@mui/material/Avatar';

import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from "@mui/material/Paper";


import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ProfileState } from "../Atoms/ProfileAtom";
import yellow from "@mui/material/colors/yellow";


//icons
import { styled } from '@mui/material/styles';
import Copyright from './Copyright';

import { getUpdateUser, UpdateTag, uploudPicture } from "../API/Agent";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles({
    input1: {
        fontSize: "1vw"
    },
    input2: {
        fontSize: "15Px",
        textTransform: 'capitalize'
    },
    input3: {
        fontSize: "14px",
        textTransform: 'lowercase'
    }
});
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const Input = styled('input')({
    display: 'none',
});


const theme = createTheme({
    palette: {
        primary: {
            light: '#757ce8',
            main: '#1976D2',
            dark: '#FFD700',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#fff',
        },
    },
});



const UserUpdate = () => {
    const { title, setTitle } = useContext(GlobalContext);
    const [isPet, setIsPet] = useState(false);
    const [profile, setProfile] = useRecoilState(ProfileState);
    let { userId, otp } = useParams();
    let navigate = useNavigate();
    const classes = useStyles();
    const [showPurpos, setShowPurpose] = useState(false);
    const [sex, setSex] = useState(false);
    const [gender, setGender] = useState("");
    const [isSterile, setIsSterile] = useState(false);
    const [sterilized, setSterilized] = useState("");
    const [isChipped, setIsChipped] = useState(false);
    const [chipped, setChipped] = useState("");
    const [istruck, setIstruck] = useState(false);
    const [isvehicle, setIsvehicle] = useState(false);
    const [ismotorbike, setIsmotorbike] = useState(false);
    const [isbike, setIsbike] = useState(false);
    const [isother, setIsother] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [travelling_international, setInternational] = useState("");
    const [purpose, setPurpose] = useState("");
    const [updated, setUpdated] = useState(false);
    const [images, setImages] = useState([]);
    const [imageURLs, setimageURLs] = useState([]);
    const [isPT, setIsPT] = useState(false);


    const onChangePicture = async (e) => {

        setImages([...e.target.files]);
        const image = e.target.files[0];
        console.log(data.member_number);
        const url = await uploudPicture(image, data.member_number);
        setimageURLs([url]);

    };


    useEffect(() => {
        getUpdateUser(userId, otp).then((resp) => {
            // console.log(resp);
            // setUser(resp);

            setData(resp);
            setIsPet(resp.member_number.substring(0, 3) === "RTP");
            setIsPT(resp.member_number.substring(0, 2) === "PT");
            resp.member_number.substring(0, 3) === "RTP" ? setTitle("EDIT RESQTAP PETS") : setTitle("EDIT RESQTAP");
            resp.travelling_international === "YES - I am a Traveler " ? setShowPurpose(true) : setShowPurpose(false)

            setIstruck(resp.istruck)
            setIsvehicle(resp.isvehicle)
            setIsmotorbike(resp.ismotorbike)
            setIsbike(resp.isbike)
            setIsother(resp.isother)


            if (images.length < 1) {
                setimageURLs([resp.photo]);
                return;
            }

            const newImageUrls = [];
            images.forEach(image => newImageUrls.push(URL.createObjectURL(image)));
            setimageURLs(newImageUrls);


        });


    }, [userId, otp, images])



    const updateData = async (event) => {
        event.preventDefault();
        //cloudinary connect

        const dat = new FormData(event.currentTarget);


        let tag =
        {
            id: dat.get('id')===null?'':dat.get('id'),
            id_no: dat.get('id_no')===null?'':dat.get('id_no'),
            id_number: dat.get('id_number')===null?'':dat.get('id_number'),
            names: dat.get('Names')===null?'':dat.get('Names'),
            allergies: dat.get('Allergies')===null?'':dat.get('Allergies'),
            email: dat.get('Email')===null?'':dat.get('Email'),
            vaccination_code: dat.get('Covid') === '' || dat.get('Covid') === null ? '' : dat.get('Covid'),
            travelvaccination: dat.get('travelvaccination') === '' || dat.get('travelvaccination') === null ? '' : dat.get('travelvaccination'),
            country: dat.get('Country') === '' || dat.get('Country') === null ? '' : dat.get('Country'),
            medical_contions: dat.get('Medical_contions') === '' || dat.get('Medical_contions') === null ? '' : dat.get('Medical_contions'),
            medication: dat.get('Medication')===null?'':dat.get('Medication'),
            medical_aid: dat.get('MedicalAid') === '' || dat.get('MedicalAid') === null ? '' : dat.get('MedicalAid'),// dat.get('MedicalAid'),
            medical_aid_no: dat.get('Medical_aid_no') === '' || dat.get('Medical_aid_no') === null ? '' : dat.get('Medical_aid_no'),// dat.get('Medical_aid_no'),
            blood_group: dat.get('Blood') === '' || dat.get('Blood') === null ? '' : dat.get('Blood'),// dat.get('Blood'),
            ice1_name: dat.get('Ice1_name'),
            ice1_no: dat.get('Ice1_no'),
            ice2_name: dat.get('Ice2_name'),
            ice2_no: dat.get('Ice2_no'),
            ice3_name: dat.get('Ice3_name'),
            ice3_no: dat.get('Ice3_no'),
            notes: dat.get('Notes')===null?'':dat.get('Notes'),
            member_number: dat.get('MemberNumber')===null?'':dat.get('MemberNumber'),
            travelling_international: dat.get('International')===null?'':dat.get('International'),
            purpose: dat.get('Purpose') === '' || dat.get('Purpose') === null ? '' : dat.get('Purpose'),
            tagsViewCount: dat.get('tagsViewCount')===null?0:dat.get('tagsViewCount'),
            visitAt: dat.get('visitAt')===null?'':dat.get('visitAt'),
            photo: imageURLs[0],
            creditCount: dat.get('creditCount')===null?0:dat.get('creditCount'),
            creditDate: dat.get('creditDate')===null?'':dat.get('creditDate'),
            isBillable: dat.get('isBillable')===null?true:dat.get('isBillable'),
            istruck,
            isvehicle,
            ismotorbike,
            isbike,
            isother,
            truck: dat.get('truck') === '' || dat.get('truck') === null ? '' : dat.get('truck'),//dat.get('truck'),
            vehicle: dat.get('vehicle') === '' || dat.get('vehicle') === null ? '' : dat.get('vehicle'),//dat.get('vehicle'),
            motorbike: dat.get('motorbike') === '' || dat.get('motorbike') === null ? '' : dat.get('motorbike'),//dat.get('motorbike'),
            bike: dat.get('bike') === '' || dat.get('bike') === null ? '' : dat.get('bike'),//dat.get('bike'),
            other: dat.get('other') === '' || dat.get('other') === null ? '' : dat.get('other'),//dat.get('other'),
            active: dat.get('active')===null?true:dat.get('active'),

            breed: dat.get('breed') === '' || dat.get('breed') === null ? '' : dat.get('breed'),//dat.get('breed'),
            colour: dat.get('colour') === '' || dat.get('colour') === null ? '' : dat.get('colour'),//dat.get('colour'),
            gender: dat.get('gender') === '' || dat.get('gender') === null ? '' : dat.get('gender'),//dat.get('gender'),
            sterilized: dat.get('sterilized') === '' || dat.get('sterilized') === null ? '' : dat.get('sterilized'),//dat.get('sterilized'),
            chipped: dat.get('chipped') === '' || dat.get('chipped') === null ? '' : dat.get('chipped'),//dat.get('chipped')pet_type
            pet_type: dat.get('pet_type') === '' || dat.get('pet_type') === null ? '' : dat.get('pet_type'),//dat.get('chipped')pet_type
        };
        //console.log(tag);

        setLoading(true);
        // if (travelling_international === 'NO - I am Local') {
        //     setPurpose(null);
        // }
        UpdateTag(tag, profile.role).then((response) => {




            if (response !== undefined && response !== "—") {
                // handleClickOpen(); //fix this
                // navigate("/login");
                setLoading(false);
                setUpdated(true);
                if (profile.role > 2) {
                    navigate("/admin");
                } else {
                    navigate("/profile");
                }

            } else {
                //do something here placeholder
            }
        });

    }


    const handleChange = (event) => {
        console.log(event);

        switch (event.target.name) {

            case "istruck":
                setIstruck(event.target.checked);
                break;
            case "isvehicle":
                setIsvehicle(event.target.checked);
                break;
            case "ismotorbike":
                setIsmotorbike(event.target.checked);
                break;
            case "isbike":
                setIsbike(event.target.checked);
                break;
            case "isother":
                setIsother(event.target.checked);
                break;
        }

    };



    return (
        (data ?? []).length === 0 ?
            <Loading />
            :
            <React.Fragment>

                <ThemeProvider theme={theme}>

                    <Paper elevation={12} style={{
                        margin: "auto",
                        width: '80%',

                        border: `5px solid ${yellow[100]}`,
                        backgroundColor: yellow[50],
                        borderRadius: 25,
                    }} >
                        <Box p={1}>
                            <Container component="main" maxWidth="xs">
                                <CssBaseline />
                                <Box
                                    sx={{
                                        display: 'flex',

                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >

                                    {isPet ? <img width={200} height={200} alt={data.names} src={pets} />
                                        : <img width={200} height={200} alt={data.names} src={logo} />
                                    }
                                    <Typography component="h1" variant="h5">
                                        Be Prepared, Save a Life!™️
                                    </Typography>

                                    <Avatar src={imageURLs[0]} style={{
                                        width: 350,
                                        height: 350,

                                    }} variant="square" />
                                    <Typography component="h6" variant="h6">
                                        Update your Photo

                                        <label htmlFor="icon-button-file">
                                            <Input accept="image/*" id="icon-button-file" type="file" onChange={onChangePicture} />
                                            <IconButton sx={{ width: 35 }} color="primary" aria-label="upload picture" component="span">
                                                <PhotoCamera />
                                            </IconButton>
                                        </label>
                                    </Typography>
                                    <Box onSubmit={updateData} component="form" noValidate sx={{ mt: 3 }}>
                                        <TextField
                                            name="id_no"
                                            type="hidden"
                                            style={{ display: 'none' }}
                                            value={data.id_no}
                                        />
                                        <TextField
                                            name="id"
                                            type="hidden"
                                            style={{ display: 'none' }}
                                            value={data.id}
                                        />
                                        <TextField
                                            name="visitAt"
                                            type="hidden"
                                            style={{ display: 'none' }}
                                            value={data.visitAt}
                                        />
                                        <TextField
                                            name="tagsViewCount"
                                            type="hidden"
                                            style={{ display: 'none' }}
                                            value={data.tagsViewCount}
                                        />
                                        <TextField
                                            name="active"
                                            type="hidden"
                                            style={{ display: 'none' }}
                                            value={data.active}
                                        />
                                        <TextField
                                            name="isBillable"
                                            type="hidden"
                                            style={{ display: 'none' }}
                                            value={data.isBillable}
                                        />
                                        <TextField
                                            name="creditCount"
                                            type="hidden"
                                            style={{ display: 'none' }}
                                            value={data.creditCount}
                                        />
                                        <TextField
                                            name="creditDate"
                                            type="hidden"
                                            style={{ display: 'none' }}
                                            value={data.creditDate}
                                        />
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} >
                                                <TextField
                                                    id="Names"
                                                    name="Names"
                                                    multiline
                                                    fullWidth
                                                    variant="outlined" size="small"
                                                    label={isPet ? "PET NAME" : "NAME & SURNAME"}
                                                    defaultValue={data.names}

                                                    InputProps={{

                                                        classes: { input: classes.input2 }
                                                    }}
                                                />

                                            </Grid>
                                            <Grid item xs={12} >
                                                <TextField
                                                    id="id_number"
                                                    name="id_number"
                                                    multiline
                                                    fullWidth
                                                    variant="outlined" size="small"
                                                    label={isPet ? "BIRTHDAY" : "ID OR PASSPORT NUMBER"}
                                                    defaultValue={data.id_number}

                                                    InputProps={{
                                                        readOnly: false,
                                                        classes: { input: classes.input2 }
                                                    }}
                                                />

                                            </Grid>
                                            {!isPet ? null :
                                                <Grid item xs={12} >
                                                    <TextField
                                                        id="pet_type"
                                                        name="pet_type"
                                                        multiline
                                                        variant="outlined" size="small"
                                                        fullWidth
                                                        label="PET TYPE"
                                                        defaultValue={data.pet_type}

                                                        InputProps={{

                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                            }
                                            {!isPet ? null :
                                                <Grid item xs={12} >
                                                    <TextField
                                                        id="breed"
                                                        name="breed"
                                                        multiline
                                                        variant="outlined" size="small"
                                                        fullWidth
                                                        label="BREED"
                                                        defaultValue={data.breed}

                                                        InputProps={{

                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                            }
                                            {!isPet ? null :
                                                <Grid item xs={12} >
                                                    <TextField
                                                        id="colour"
                                                        name="colour"
                                                        multiline
                                                        variant="outlined" size="small"
                                                        fullWidth
                                                        label="COLOUR"
                                                        defaultValue={data.colour}

                                                        InputProps={{

                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                            }
                                            {!isPet ? null :
                                                <Grid item xs={12} >
                                                    <Box sx={{ p: 1, border: 1, borderColor: 'grey.500' }} >
                                                        <FormControl>
                                                            <FormLabel id="GENDER">GENDER?</FormLabel>
                                                            <RadioGroup
                                                                row
                                                                aria-labelledby="GENDER"
                                                                id="gender"
                                                                name="gender"
                                                                defaultValue={data.gender}
                                                                onChange={(e, value) => {
                                                                    value === "MALE" ? setSex(true) : setSex(false)
                                                                    setGender(value)
                                                                    console.log(value);
                                                                }}
                                                            >
                                                                <FormControlLabel value="MALE" control={<Radio />} label="MALE" />
                                                                <FormControlLabel value="FEMALE" control={<Radio />} label="FEMALE" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </Box>
                                                </Grid>
                                            }
                                            {!isPet ? null :
                                                <Grid item xs={12} >
                                                    <Box sx={{ p: 1, border: 1, borderColor: 'grey.500' }} >
                                                        <FormControl>
                                                            <FormLabel id="STERILIZED">STERILIZED?</FormLabel>
                                                            <RadioGroup
                                                                row
                                                                aria-labelledby="STERILIZED"
                                                                id="sterilized"
                                                                name="sterilized"
                                                                defaultValue={data.sterilized}
                                                                onChange={(e, value) => {
                                                                    value === "YES" ? setIsSterile(true) : setIsSterile(false)
                                                                    setSterilized(value)
                                                                    console.log(value);
                                                                }}
                                                            >
                                                                <FormControlLabel value="YES" control={<Radio />} label="YES" />
                                                                <FormControlLabel value="NO" control={<Radio />} label="NO" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </Box>
                                                </Grid>
                                            }
                                            {!isPet ? null :
                                                <Grid item xs={12} >
                                                    <Box sx={{ p: 1, border: 1, borderColor: 'grey.500' }} >
                                                        <FormControl>
                                                            <FormLabel id="CHIPPED">CHIPPED?</FormLabel>
                                                            <RadioGroup
                                                                row
                                                                aria-labelledby="CHIPPED"
                                                                id="chipped"
                                                                name="chipped"
                                                                defaultValue={data.chipped}
                                                                onChange={(e, value) => {
                                                                    value === "YES" ? setIsChipped(true) : setIsChipped(false)
                                                                    setChipped(value)
                                                                    console.log(value);
                                                                }}
                                                            >
                                                                <FormControlLabel value="YES" control={<Radio />} label="YES" />
                                                                <FormControlLabel value="NO" control={<Radio />} label="NO" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </Box>
                                                </Grid>
                                            }
                                            {isPT || isPet ? null :
                                                <Grid item xs={12} >
                                                    <TextField
                                                        id="Covid"
                                                        name="Covid"
                                                        multiline
                                                        variant="outlined" size="small"
                                                        fullWidth
                                                        label="COVID-19 VACCINATION CODE"
                                                        defaultValue={data.vaccination_code}

                                                        InputProps={{

                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                            }
                                            {isPT || isPet ? null :
                                                <Grid item xs={12} >
                                                    <TextField
                                                        id="travelvaccination"
                                                        name="travelvaccination"
                                                        multiline
                                                        variant="outlined" size="small"
                                                        fullWidth
                                                        label="TRAVEL VACCINATIONS"
                                                        defaultValue={data.travelvaccination}

                                                        InputProps={{

                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                            }
                                            {isPT ? null :
                                                <Grid item xs={12} >
                                                    <TextField
                                                        id="Allergies"
                                                        name="Allergies"
                                                        multiline
                                                        variant="outlined" size="small"
                                                        fullWidth
                                                        label="ALLERGIES (PER LINE)"
                                                        defaultValue={data.allergies}

                                                        InputProps={{

                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                            }
                                            {isPT ? null :
                                                <Grid item xs={12} >
                                                    <TextField
                                                        id="Medical_contions"
                                                        name="Medical_contions"
                                                        variant="outlined" size="small"
                                                        fullWidth
                                                        label="MEDICAL CONDITIONS (PER LINE)"
                                                        multiline
                                                        defaultValue={data.medical_contions}

                                                        InputProps={{

                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                            }
                                            {isPT ? null :
                                                <Grid item xs={12} >
                                                    <TextField
                                                        id="Medication"
                                                        name="Medication"
                                                        variant="outlined" size="small"
                                                        fullWidth
                                                        label="CHRONIC MEDICATION (PER LINE)"
                                                        multiline
                                                        defaultValue={data.medication}

                                                        InputProps={{

                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                            }
                                            {isPT ? null :
                                                <Grid item xs={12} >
                                                    <TextField
                                                        id="MedicalAid"
                                                        name="MedicalAid"
                                                        multiline
                                                        variant="outlined" size="small"
                                                        fullWidth
                                                        label={isPet ? "Pet INSURANCE NAME" : "MEDICAL AID NAME"}
                                                        defaultValue={data.medical_aid}

                                                        InputProps={{

                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                            }
                                            {isPT ? null :
                                                <Grid item xs={12} >
                                                    <TextField
                                                        id="Medical_aid_no"
                                                        name="Medical_aid_no"
                                                        multiline
                                                        variant="outlined" size="small"
                                                        fullWidth
                                                        label={isPet ? "Pet INSURANCE NUMBER" : "MEDICAL AID NUMBER"}
                                                        defaultValue={data.medical_aid_no}

                                                        InputProps={{

                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                            }
                                            {isPT || isPet ? null :
                                                <Grid item xs={12} >
                                                    <TextField
                                                        id="Blood"
                                                        name="Blood"
                                                        multiline
                                                        variant="outlined" size="small"
                                                        fullWidth
                                                        label="BLOOD GROUP"
                                                        defaultValue={data.blood_group}

                                                        InputProps={{

                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                            }
                                            {isPT ? null :
                                                <Grid item xs={12} >
                                                    <TextField
                                                        id="Notes"
                                                        name="Notes"
                                                        variant="outlined" size="small"
                                                        fullWidth
                                                        label="SPECIAL REQUESTS / NOTES"
                                                        defaultValue={data.notes}

                                                        multiline
                                                        InputProps={{

                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                            }


                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    id="Ice1_name"
                                                    name="Ice1_name"
                                                    multiline
                                                    variant="outlined" size="small"
                                                    fullWidth
                                                    label="ICE CONTACT 1"
                                                    defaultValue={data.ice1_name}

                                                    InputProps={{

                                                        classes: { input: classes.input2 }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    id="Ice1_no"
                                                    name="Ice1_no"
                                                    multiline
                                                    variant="outlined" size="small"
                                                    fullWidth
                                                    label="ICE CONTACT 1 NUMBER"
                                                    defaultValue={data.ice1_no}

                                                    InputProps={{

                                                        classes: { input: classes.input2 }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    id="Ice2_name"
                                                    name="Ice2_name"
                                                    multiline
                                                    variant="outlined" size="small"
                                                    fullWidth
                                                    label="ICE CONTACT 2"
                                                    defaultValue={data.ice2_name}

                                                    InputProps={{

                                                        classes: { input: classes.input2 }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    id="Ice2_no"
                                                    name="Ice2_no"
                                                    multiline
                                                    variant="outlined" size="small"
                                                    fullWidth
                                                    label="ICE CONTACT 2 NUMBER"
                                                    defaultValue={data.ice2_no}

                                                    InputProps={{

                                                        classes: { input: classes.input2 }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    id="Ice3_name"
                                                    name="Ice3_name"
                                                    multiline
                                                    variant="outlined" size="small"
                                                    fullWidth
                                                    label={isPet ? "VET" : "ICE CONTACT 3"}
                                                    defaultValue={data.ice3_name}

                                                    InputProps={{

                                                        classes: { input: classes.input2 }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    id="Ice3_no"
                                                    name="Ice3_no"
                                                    multiline
                                                    variant="outlined" size="small"
                                                    fullWidth
                                                    label={isPet ? "VET NUMBER" : "ICE CONTACT 3 NUMBER"}
                                                    defaultValue={data.ice3_no}

                                                    InputProps={{

                                                        classes: { input: classes.input2 }
                                                    }}
                                                />
                                            </Grid>
                                            {isPT ? null :
                                                <Grid item xs={12} >
                                                    <TextField
                                                        id="Country"
                                                        name="Country"
                                                        multiline
                                                        variant="outlined" size="small"
                                                        fullWidth
                                                        label="COUNTRY OF RESIDENCE"
                                                        defaultValue={data.country}

                                                        InputProps={{

                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                            }
                                            {isPT ? null :
                                                <Grid item xs={12} >
                                                    <Box sx={{ p: 1, border: 1, borderColor: 'grey.500' }} >

                                                        <FormControl>
                                                            <FormLabel id="TRAVELLING">TRAVELLING INTERNATIONALLY?</FormLabel>
                                                            <RadioGroup
                                                                row
                                                                aria-labelledby="TRAVELLING"
                                                                id="International"
                                                                name="International"
                                                                defaultValue={data.travelling_international}
                                                                onChange={(e, value) => {
                                                                    value === "YES - I am a Traveler " ? setShowPurpose(true) : setShowPurpose(false)
                                                                    setInternational(value)
                                                                    console.log(value);
                                                                }}
                                                            >
                                                                <FormControlLabel value="NO - I am Local" control={<Radio />} label="NO - I am Local" />
                                                                <FormControlLabel value="YES - I am a Traveler " control={<Radio />} label="YES - I am a Traveler " />

                                                            </RadioGroup>
                                                        </FormControl>

                                                    </Box>


                                                </Grid>
                                            }

                                            <Grid item xs={12}  >
                                                {/*none block */}
                                                <Box sx={{ p: 1, border: 1, borderColor: 'grey.500' }} display={{ xs: `${!showPurpos ? 'none' : 'block'}`, lg: `${!showPurpos ? 'none' : 'block'}` }}>

                                                    <FormControl>
                                                        <FormLabel id="PURPOSE">PURPOSE?</FormLabel>
                                                        <RadioGroup

                                                            id="Purpose"
                                                            aria-labelledby="PURPOSE"
                                                            name="Purpose"
                                                            defaultValue={data.purpose}
                                                            onChange={(e, value) => {
                                                                setPurpose(value)
                                                                console.log(value);
                                                            }}
                                                        >
                                                            <FormControlLabel value="WORK" control={<Radio />} label="WORK" />
                                                            <FormControlLabel value="HOLIDAY" control={<Radio />} label="HOLIDAY" />
                                                            <FormControlLabel value="OTHER" control={<Radio />} label="OTHER" />
                                                        </RadioGroup>
                                                    </FormControl>

                                                </Box>

                                            </Grid>

                                            {isPet || isPT ? null :
                                                <Grid item xs={12} >
                                                    <Box sx={{ p: 1, border: 1, borderColor: 'grey.500' }} >

                                                        <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
                                                            <FormLabel id="TRAVELLING">TRANSPORT INFORMATION (Choose 1 or more)</FormLabel>
                                                            <FormGroup>
                                                                <FormControlLabel control={<Checkbox name="isvehicle" checked={isvehicle} onChange={handleChange} />} label="MOTOR VEHICLE" />
                                                                <FormControlLabel control={<Checkbox name="istruck" checked={istruck} onChange={handleChange} />} label="TRUCK" />
                                                                <FormControlLabel control={<Checkbox name="ismotorbike" checked={ismotorbike} onChange={handleChange} />} label="MOTORCYCLE" />
                                                                <FormControlLabel control={<Checkbox name="isbike" checked={isbike} onChange={handleChange} />} label="BICYCLE" />
                                                                <FormControlLabel control={<Checkbox name="isother" checked={isother} onChange={handleChange} />} label="OTHER" />
                                                            </FormGroup>
                                                        </FormControl>

                                                    </Box>


                                                </Grid>
                                            }
                                            {isvehicle ?
                                                <Grid item xs={12} >
                                                    <TextField
                                                        id="vehicle"
                                                        name="vehicle"
                                                        variant="outlined" size="small"
                                                        fullWidth
                                                        label="vehicle"
                                                        defaultValue={data.vehicle}

                                                        multiline
                                                        InputProps={{

                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                                : null}
                                            {istruck ?
                                                <Grid item xs={12} >
                                                    <TextField
                                                        id="truck"
                                                        name="truck"
                                                        variant="outlined" size="small"
                                                        fullWidth
                                                        label="Truck"
                                                        defaultValue={data.truck}

                                                        multiline
                                                        InputProps={{

                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                                : null}

                                            {ismotorbike ?
                                                <Grid item xs={12} >
                                                    <TextField
                                                        id="motorbike"
                                                        name="motorbike"
                                                        variant="outlined" size="small"
                                                        fullWidth
                                                        label="motorbike"
                                                        defaultValue={data.motorbike}

                                                        multiline
                                                        InputProps={{

                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                                : null}
                                            {isbike ?
                                                <Grid item xs={12} >
                                                    <TextField
                                                        id="bike"
                                                        name="bike"
                                                        variant="outlined" size="small"
                                                        fullWidth
                                                        label="bike"
                                                        defaultValue={data.bike}

                                                        multiline
                                                        InputProps={{

                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                                : null}
                                            {isother ?
                                                <Grid item xs={12} >
                                                    <TextField
                                                        id="other"
                                                        name="other"
                                                        variant="outlined" size="small"
                                                        fullWidth
                                                        label="other"
                                                        defaultValue={data.other}

                                                        multiline
                                                        InputProps={{

                                                            classes: { input: classes.input2 }
                                                        }}
                                                    />
                                                </Grid>
                                                : null}




                                            <Grid item xs={12} >
                                                <TextField
                                                    id="MemberNumber"
                                                    name="MemberNumber"
                                                    multiline
                                                    variant="outlined" size="small"
                                                    fullWidth
                                                    label="MEMBERSHIP NO."
                                                    defaultValue={data.member_number}

                                                    InputProps={{
                                                        readOnly: true,
                                                        classes: { input: classes.input2 }
                                                    }}
                                                />
                                            </Grid>


                                            <Grid item xs={12} >
                                                <TextField
                                                    id="Email"
                                                    name="Email"
                                                    multiline
                                                    variant="outlined" size="small"
                                                    fullWidth
                                                    label="EMAIL"
                                                    defaultValue={data.email}

                                                    InputProps={{

                                                        classes: { input: classes.input3 }
                                                    }}
                                                />
                                            </Grid>



                                        </Grid>

                                        <Box sx={{ mt: 3, position: 'relative' }}>
                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"

                                            >
                                                Update Member ({data.member_number})
                                            </Button>


                                        </Box>


                                    </Box>
                                </Box>

                            </Container>
                        </Box>

                        <Box>
                            <Copyright sx={{ mt: 5 }} /> &nbsp;
                            <Box display="flex" alignItems="center" justifyContent="center" component="div">ICE-TAGS System v2.5</Box>
                        </Box>
                    </Paper>
                </ThemeProvider>
            </React.Fragment>

    );
};

export default UserUpdate;
