// src/atoms/name.js

import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const ProfileState = atom({
  key: "ProfileState",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export const textState = atom({
  key: 'textState', // unique ID (with respect to other atoms/selectors)
  default: '', // default value (aka initial value)
});

export const RefreshState = atom({
  key: 'RefreshState', // unique ID (with respect to other atoms/selectors)
  default: 1, // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});

export const shoppingList = atom({
  key: "shoppingList",
  default: ["Milk", "Bread", "Cheese"], // default value
})
export const profileLength = selector({
  key: 'profileLength', // unique ID (with respect to other atoms/selectors)
  get: ({ get }) => {
    const len = get(ProfileState);
    try {
      return len.member_numbers.length;
    }
    catch {
      return 0;
    }

  },
});

export const charCountState = selector({
  key: 'charCountState', // unique ID (with respect to other atoms/selectors)
  get: ({ get }) => {
    const text = get(textState);
    try {
      return text.length;
    }
    catch {
      return 0;
    }

  },
});

export const ProfileStateChange = selector({
  key: "ProfileStateChange",
  get: ({ get }) => {
    return get(ProfileState);
  },
  set: ({ set }, newValue) => set(ProfileState, newValue)
})


export const RefreshStateChange = selector({
  key: "RefreshStateChange",
  get: ({ get }) => {
    return get(RefreshState);
  },
  set: ({ set }, newValue) => set(RefreshState, newValue)
})