import React, { useState, useEffect } from "react";
import {

    useRecoilState,

} from 'recoil';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable, { MTableToolbar } from '@material-table/core';
import Loading from './Loading';
import FormControl from '@mui/material/FormControl';
import AddIcon from '@mui/icons-material/Add';
import MenuItem from '@mui/material/MenuItem';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import Fab from '@mui/material/Fab';
//icons
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import Delete from '@material-ui/icons/Delete';
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import Refresh from '@material-ui/icons/Refresh';
import Save from '@material-ui/icons/Save';
import RestoreIcon from "@material-ui/icons/PermIdentity";
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ProfileState } from "../Atoms/ProfileAtom";
import { register, profiles, getProfileBillable, update, deleteUser, reactivate, deleteAuth, 
    alighnprofile, clearprofiletoken,alignUsersAccounts,corpProfiles } from "../API/Agent";
import Copyright from './Copyright';
import { InputLabel } from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';
import GlobalContext from "../Context/Context";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
const useStyles = makeStyles({
    root: {
        width: "100%",
        height: "100%"
    }
});


const Users = () => {
    const { title, setTitle } = React.useContext(GlobalContext);
    const rowsPerPage = 25;
    let navigate = useNavigate();
    const [profile, setProfile] = useRecoilState(ProfileState);
    const tableRef = React.createRef();
    const classes = useStyles();
    const [firstname, setFirstName] = React.useState("");
    const [role, setRole] = React.useState("");
    const [id, setId] = React.useState("");
    const [lastname, setLastName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [id_number, setId_Number] = React.useState("");
    const [cellNumber, setCellNumber] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [users, setUsers] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [del, setDelOpen] = React.useState(false);
    const [add, setAddOpen] = React.useState(false);
    useEffect(async () => {

        if (typeof (profile._id) === "undefined") navigate("/login");
        if (profile.role <= 2) navigate("/profile");
        if(profile.isCorperate){
            setTitle(profile.corporate.corporateName +" - PROFILES");
            corpProfiles(profile._id).then((response) => {
    
                if (response !== "undefined") {
                    response.sort(function (a, b) {
                        var x = new Date(a.createdAt);
                        var y = new Date(b.createdAt);
                        return x ? -1 : y ? 1 : 0;
                    });
                    setUsers(response);
    
                } else {
                    navigate("/login");
                }
            });
        }else{
            setTitle("RESQTAP PROFILES");
            profiles().then((response) => {
    
                if (response !== "undefined") {
                    response.sort(function (a, b) {
                        var x = new Date(a.createdAt);
                        var y = new Date(b.createdAt);
                        return x ? -1 : y ? 1 : 0;
                    });
                    setUsers(response);
    
                } else {
                    navigate("/login");
                }
            });
        }
        
    }, [])

    const userRoles = (i) => {
        switch (i) {
            case 0:
                return "None";
            case 1:
                return "Subscriber";
            case 2:
                return "Company";
            case 3:
                return "Admin";
        }
    }

    const synchMobileUser = async (email) => {
        await alighnprofile(email);
        alert("Synch Mobile User");

    }

    const clearMobileUserToken = async (email) => {
        await clearprofiletoken(email);
        alert("Profile on Mobile User Cleared");

    }
    const alignAllUsersAccounts = async (email) => {
        await alignUsersAccounts(email);
        alert("All Users Have Been Aligned");

    }
    const handleDelClickOpen = () => {
        setDelOpen(true);
    };
    const handleReactivation = (profile) => {
        reactivate(profile);
        alert("Reminder Sent to " + profile.firstname + " " + profile.lastname);
    };

    const handleDelClose = () => {
        setDelOpen(false);
    };

    const handleDelAction = (email) => {
        setDelOpen(false);
        deleteUser(id).then((response) => {

            if (response !== '—') {
               // deleteAuth(email);
                profiles().then((response) => {
                    if (response !== "undefined") {
                        response.sort(function (a, b) {
                            var x = new Date(a.createdAt);
                            var y = new Date(b.createdAt);
                            return x ? -1 : y ? 1 : 0;
                        });
                        setUsers(response);
                        tableRef.current.onQueryChange();
                    } else {
                        navigate("/login");
                    }
                });

            } else {
                //do something here placeholder
            }
        });
    };
    const handleAddNewUser = () => {
        setAddOpen(true);
    }
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleAddClose = () => {
        setAddOpen(false);
    };

    const handleUpdateAndClose = () => {

        let userRegister =
        {
            email: email,
            password: password,
            firstname: firstname,
            lastname: lastname,
            id_number: id_number,
            role: role,
            cellNumber
        };

        try {
            console.log(userRegister);
            update(userRegister, id).then((response) => {

                if (response !== '—') {
                    profiles().then((response) => {
                        if (response !== "undefined") {
                            response.sort(function (a, b) {
                                var x = new Date(a.createdAt);
                                var y = new Date(b.createdAt);
                                return x ? -1 : y ? 1 : 0;
                            });
                            setUsers(response);
                            alert("Profile Updated");
                            tableRef.current.onQueryChange();
                            
                        } else {
                            navigate("/login");
                        }
                    });

                } else {
                    //do something here placeholder
                }
            });
        } catch (error) {
            console.log({ ...error })

        }
        setOpen(false);
    };

    const handleAddAndClose = () => {

        let userRegister =
        {
            email: email,
            password: password,
            firstname: firstname,
            lastname: lastname,
            id_number: id_number,
            role: role
        };

        try {
            console.log(userRegister);
            register(userRegister).then((response) => {

                if (response !== '—') {
                    profiles().then((response) => {
                        if (response !== "undefined") {
                            response.sort(function (a, b) {
                                var x = new Date(a.createdAt);
                                var y = new Date(b.createdAt);
                                return x ? -1 : y ? 1 : 0;
                            });
                            setUsers(response);
                            tableRef.current.onQueryChange();
                        } else {
                            navigate("/login");
                        }
                    });

                } else {
                    //do something here placeholder
                }
            });
        } catch (error) {
            console.log({ ...error })

        }
        setAddOpen(false);
    };

    const setBillible = async (user) => {

        await   getProfileBillable(user._id, !user.isBillable).then((response) => {
            if (response !== '—') {
                if(profile.isCorperate){
                   
                    corpProfiles(profile._id).then((response) => {
            
                       
                            response.sort(function (a, b) {
                                var x = new Date(a.createdAt);
                                var y = new Date(b.createdAt);
                                return x ? -1 : y ? 1 : 0;
                            });
                            setUsers(response);
            
                      
                    });
                }else{
                    
                    profiles().then((response) => {
            
                        
                            response.sort(function (a, b) {
                                var x = new Date(a.createdAt);
                                var y = new Date(b.createdAt);
                                return x ? -1 : y ? 1 : 0;
                            });
                            setUsers(response);
            
                       
                    });
                }

            } else {
                //do something here placeholder
            }
        });
    }

    return (
        (users ?? []).length === 0 ?
            <Loading />
            :
            <>
                <CssBaseline />
                <div className={classes.root}>
                    <MaterialTable
                        title="ICE - TAGS - ALL PROFILES"
                        tableRef={tableRef}

                        columns={[

                            { title: "Firstname", field: "firstname", width: "10%" },
                            { title: "Lastname", field: "lastname", width: "10%" },
                            { title: "Email", field: "email", width: "10%" },
                            { title: "ID Number", field: "id_number", width: "10%" },
                            { title: "Active", field: "active", width: "10%" },
                            {
                                title: "Role",
                                field: "role",
                                width: "10%",
                                render: (rowData) => <><b>{userRoles(rowData.role)}</b></>
                            },


                        ]}
                        data={users}
                        components={{
                            Toolbar: props => (
                                <div>
                                    <MTableToolbar {...props} />
                                    <div style={{ padding: '0px 10px' }}>
                                        <Tooltip title="Add New User">
                                            <Fab onClick={handleAddNewUser} size="small" color="primary" aria-label="add">
                                                <AddIcon />
                                            </Fab>
                                        </Tooltip>

                                    </div>
                                </div>
                            ),
                        }}
                        actions={[
                            // {
                            //     width: 10,
                            //     icon: Refresh,
                            //     tooltip: 'Activate/Deactivate User',
                            //     onClick: (event, rowData) => {
                            //         console.log(rowData);

                            //         activate(rowData.id, rowData.active).then((response) => {

                            //             if (response !== "undefined") {
                            //                 profiles().then((response) => {
                            //                     if (response !== "undefined") {
                            //                         response.sort(function (a, b) {
                            //                             var x = new Date(a.createdAt);
                            //                             var y = new Date(b.createdAt);
                            //                             return  x ? -1 : y ? 1 : 0;
                            //                         });
                            //                         setUsers(response);
                            //                         tableRef.current.onQueryChange();
                            //                     } else {
                            //                         navigate("/login");
                            //                     }
                            //                 });
                            //             } else {
                            //                 navigate("/login");
                            //             }
                            //         });

                            //     }
                            // },
                            {
                                width: 10,
                                icon: Save,
                                tooltip: 'Update User',
                                onClick: (event, rowData) => {
                                    setId(rowData.id);
                                    setFirstName(rowData.firstname);
                                    setLastName(rowData.lastname);
                                    setEmail(rowData.email);
                                    setId_Number(rowData.id_number);
                                    setCellNumber(rowData.cellNumber);
                                    setPassword(rowData.password);
                                    setRole(rowData.role);
                                    handleClickOpen();
                                    //  sendUpdate(rowData);
                                }
                            },
                            {
                                width: 10,
                                icon: Delete,
                                tooltip: 'Delete User',
                                onClick: (event, rowData) => {
                                    setId(rowData.id);
                                    setEmail(rowData.email);
                                    handleDelClickOpen();
                                }
                            },
                            {
                                width: 10,
                                icon: ForwardToInboxIcon,
                                tooltip: 'Resend Activation Reminder',
                                onClick: (event, rowData) => {

                                    handleReactivation(rowData);
                                }
                            },
                            {
                                width: 10,
                                icon: MobileFriendlyIcon,
                                tooltip: 'Synch Mobile User',
                                onClick: (event, rowData) => {
                                    synchMobileUser(rowData.email);
                                }
                            },
                            rowData => ({
                                width: 10,
                                icon: PhonelinkSetupIcon,
                                tooltip: 'Clear User Token',
                                onClick: (event, rowData) => {
                                    clearMobileUserToken(rowData.email);

                                },
                                disabled: rowData.acitive
                            }),
                            rowData => ({
                                width: 10,
                                icon: RestoreIcon,
                                tooltip: 'View User',
                                onClick: (event, rowData) => {
                                    navigate("/Profile/" + rowData._id);
                                    
                                },
                                disabled: rowData.acitive
                            }),
                            rowData => ({
                                width: 10,
                                icon: PeopleOutlineIcon,
                                tooltip: 'Align Users Accounts',
                                onClick: (event, rowData) => {
                                    alignAllUsersAccounts(rowData.email);
                                    
                                },
                                disabled: rowData.acitive
                            }),
                            rowData => ({
                                width: 8,
                                icon: rowData.isBillable ? () => <AttachMoneyIcon fontSize="large"
                                    style={{ fill: '#5a8a3d', width: '32px' }} /> : () => <MoneyOffIcon fontSize="large"
                                        style={{ fill: '#c1ddb1', width: '32px' }} />,
                                tooltip: rowData.isBillable ? 'Profile is billable' : 'Profile is not billable',
                                onClick: (event, rowData) => {
                                    //alert("You saved " + rowData.names)
                                    setBillible(rowData);
                                }
                            })

                        ]}
                        options={{
                            pageSize: rowsPerPage,
                            pageSizeOptions: [25, 50, 100],
                            actionsColumnIndex: -1,
                            search: true,
                            icon: "RestoreIcon",
                            tooltip: "Refresh Data",
                            headerStyle: {
                                backgroundColor: "#ff0000",
                                color: "#FFFFFF",
                            },
                            sorting: true,
                            onClick: () => tableRef.current && tableRef.current.onQueryChange()
                        }}

                    />
                    <Dialog open={open} onClose={handleClose} sx={{ textAlign: "center" }}>
                        <DialogTitle>ICE-TAGS Update User</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Update userdetails below and click update to make the changes.
                            </DialogContentText>
                            <TextField
                                autoFocus
                                onChange={e => setFirstName(e.target.value)}
                                margin="dense"
                                id="firstname"
                                label="First Name"
                                type="text"
                                fullWidth
                                value={firstname}
                                variant="standard"
                            />
                            <TextField

                                onChange={e => setLastName(e.target.value)}
                                margin="dense"
                                id="lastname"
                                label="Last Name"
                                type="text"
                                fullWidth
                                value={lastname}
                                variant="standard"
                            />
                            <TextField

                                onChange={e => setEmail(e.target.value)}
                                margin="dense"
                                id="email"
                                label="Email"
                                type="text"
                                fullWidth
                                value={email}
                                variant="standard"
                            />
                            <TextField

                                onChange={e => setId_Number(e.target.value)}
                                margin="dense"
                                id="id_number"
                                label="ID Number"
                                type="text"
                                fullWidth
                                value={id_number}
                                variant="standard"
                            />
                            <TextField

                                onChange={e => setCellNumber(e.target.value)}
                                margin="dense"
                                id="cellPhone"
                                label="Cell Phone"
                                type="text"
                                fullWidth
                                value={cellNumber}
                                variant="standard"
                            />
                            <FormControl variant="standard" fullWidth sx={{ textAlign: "left", mt: 2 }}>
                                <InputLabel id="roleId">Role</InputLabel>
                                <Select
                                    labelId="roleId"
                                    id="role"
                                    value={role}
                                    onChange={e => setRole(e.target.value)}
                                >
                                    <MenuItem value="0">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={1}>Subscriber</MenuItem>
                                    <MenuItem value={2}>Manager</MenuItem>
                                    <MenuItem value={3}>Admin</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField

                                onChange={e => setPassword(e.target.value)}
                                margin="dense"
                                id="password"
                                label="Password"
                                type="text"
                                fullWidth
                                value={password}
                                variant="standard"
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button onClick={handleUpdateAndClose}>Update</Button>
                            {/* sendUpdate(rowData); */}
                        </DialogActions>
                    </Dialog>

                    <Dialog

                        open={del}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        sx={{ textAlign: "center" }}
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"ICE-TAGS User Delete"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure that you want to delete this user?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            {/* <Button onClick={handleClose}>Disagree</Button> */}
                            <Button onClick={handleDelClose}>Cancel</Button>
                            <Button onClick={() => { handleDelAction(email) }}>Delete</Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={add} onClose={handleAddClose} sx={{ textAlign: "center" }}>
                        <DialogTitle>Add User</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Fill in all the details and create a new user.
                            </DialogContentText>
                            <TextField
                                autoFocus
                                onChange={e => setFirstName(e.target.value)}
                                margin="dense"
                                id="firstname"
                                label="First Name"
                                type="text"
                                fullWidth
                                value={firstname}
                                variant="standard"
                            />
                            <TextField

                                onChange={e => setLastName(e.target.value)}
                                margin="dense"
                                id="lastname"
                                label="Last Name"
                                type="text"
                                fullWidth
                                value={lastname}
                                variant="standard"
                            />
                            <TextField

                                onChange={e => setEmail(e.target.value)}
                                margin="dense"
                                id="email"
                                label="Email"
                                type="text"
                                fullWidth
                                value={email}
                                variant="standard"
                            />
                            <TextField

                                onChange={e => setId_Number(e.target.value)}
                                margin="dense"
                                id="id_number"
                                label="ID Number"
                                type="text"
                                fullWidth
                                value={id_number}
                                variant="standard"
                            />
                            <TextField

                                onChange={e => setCellNumber(e.target.value)}
                                margin="dense"
                                id="cellPhone"
                                label="Cell Number"
                                type="text"
                                fullWidth
                                value={cellNumber}
                                variant="standard"
                            />
                            <FormControl variant="standard" fullWidth sx={{ textAlign: "left", mt: 2 }}>
                                <InputLabel id="roleId">Role</InputLabel>
                                <Select
                                    labelId="roleId"
                                    id="role"
                                    value={role}
                                    onChange={e => setRole(e.target.value)}
                                >
                                    <MenuItem value="0">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={1}>Subscriber</MenuItem>
                                    <MenuItem value={2}>Manager</MenuItem>
                                    <MenuItem value={3}>Admin</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField

                                onChange={e => setPassword(e.target.value)}
                                margin="dense"
                                id="password"
                                label="Password"
                                type="text"
                                fullWidth
                                value={password}
                                variant="standard"
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleAddClose}>Cancel</Button>
                            <Button onClick={handleAddAndClose}>Add</Button>
                            {/* sendUpdate(rowData); */}
                        </DialogActions>
                    </Dialog>
                </div>
                <Copyright sx={{ mt: 5 }} />
            </>
    )
}

export default Users;

