import logo from '../logo.png';

const Loading = () => (
    <div className="container">
        <img src={ logo } alt="logo" className="logo fade-in" />
        <h3>Be Prepared, Save a Life!™️</h3>

        <h1 className="fade-in2">Loading ...</h1>
    </div>
);

export default Loading;
