import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
const Copyright = (props) => {

    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://www.ice-tags.co.za">
                ICE-TAGS Products
            </Link>{' '}
            since 2013 | All Rights Reserved | ICE-TAGS (PTY) Ltd
            {'.'}
        </Typography>
    )
}

export default Copyright;